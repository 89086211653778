import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FacebookButtonComponent } from "./facebook-button/facebook-button.component";
import { GoogleButtonComponent } from "./google-button/google-button.component";
import { ChronometerCircleComponent } from "./chronometer-circle/chronometer-circle.component";
import { CounterInputComponent } from "./counter-input/counter-input.component";
import { PercentageCircleComponent } from "./percentage-circle/percentage-circle.component";
import { ProgressbarComponent } from "./progressbar/progressbar.component";
import { SelectPeriodoComponent } from "./select-periodo/select-periodo.component";
import { SkeletonItemComponent } from "./skeleton-item/skeleton-item.component";
import { EfficiencyChartsComponent } from "./statistics/efficiency-charts/efficiency-charts.component";
import { ExerciseChartsComponent } from "./statistics/exercise-charts/exercise-charts.component";
import { PageChartsComponent } from "./statistics/page-charts/page-charts.component";
import { TimeChartsComponent } from "./statistics/time-charts/time-charts.component";
import { CommonHeaderComponent } from "./common-header/common-header.component";
import { SwitchToggleComponent } from "./switch-toggle/switch-toggle.component";
import { CalendarComponent } from "./calendar-component/calendar-component.component";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { SimpleHeaderComponent } from "./simple-header/simple-header.component";
import { ExpandComponent } from "./statistics/page-charts/expand/expand.component";
import { ChartModule } from "angular2-highcharts";

@NgModule({
  declarations: [
    SimpleHeaderComponent,
    CalendarComponent,
    CommonHeaderComponent,
    CounterInputComponent,
    ChronometerCircleComponent,
    EfficiencyChartsComponent,
    ExerciseChartsComponent,
    GoogleButtonComponent,
    FacebookButtonComponent,
    PageChartsComponent,
    PercentageCircleComponent,
    ProgressbarComponent,
    SelectPeriodoComponent,
    SkeletonItemComponent,
    SwitchToggleComponent,
    TimeChartsComponent,
    ExpandComponent
  ],
  imports: [FormsModule, CommonModule, IonicModule, PipesModule, ChartModule.forRoot(require('highcharts'))],
  exports: [
    SimpleHeaderComponent,
    CalendarComponent,
    CommonHeaderComponent,
    CounterInputComponent,
    ChronometerCircleComponent,
    EfficiencyChartsComponent,
    ExerciseChartsComponent,
    GoogleButtonComponent,
    FacebookButtonComponent,
    PageChartsComponent,
    PercentageCircleComponent,
    ProgressbarComponent,
    SelectPeriodoComponent,
    SkeletonItemComponent,
    SwitchToggleComponent,
    TimeChartsComponent,
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedComponentsModule {}
