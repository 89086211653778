require("cordova-plugin-purchase");
import moment from "moment";
moment.locale('pt-BR');

import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { Component, NgZone, ViewChild } from "@angular/core";
import { IonRouterOutlet, NavController, Platform } from "@ionic/angular";

import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { StorageService } from "@app/@services/storage/storage.service";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { AmplitudeService } from "@services/amplitude/amplitude.service";
import { InAppBrowserService } from "@services/api/in-app-browser/in-app-browser.service";
import { AppService } from "./app.service";
import { NetworkService } from "@services/utils/network.service";
import { EstudoService } from "@services/api/estudo/estudo.service";
import { BasePageController } from "./pages/BasePage";
import { ColorCachingService } from "@services/api/caching/color-caching/color-caching.service";
import { CycleBlocksCachingService } from "@services/api/caching/cycle-blocks-caching/cycle-blocks-caching.service";
import { CycleCachingService } from "@services/api/caching/cycle-caching/cycle-caching.service";
import { ReviewCachingService } from "@services/api/caching/review-caching/review-caching.service";
import { StatisticCachingService } from "@services/api/caching/statistic-caching/statistic-caching.service";
import { StudyTypeCachingService } from "@services/api/caching/study-type-caching/study-type-caching.service";
import { TopicCachingService } from "@services/api/caching/topic-caching/topic-caching.service";
import { UserSubjectCachingService } from "@services/api/caching/user-subject-caching/user-subject-caching.service";
import { GeneralSubjectCachingService } from "@services/api/general-subject-caching/general-subject-caching.service";
import { SyncService } from "@services/api/sync/sync.service";
import { EventService } from "@services/event-service/event.service";
import { SentryService } from './core/sentry.service';
import { LoadingService } from "@services/loading/loading.service";
import { User } from "@models/User";
import { ReviewUtils } from "./util/review-utils";
import { Review } from "@models/Review";
import { WelcomeTrialPage } from "./pages/welcome-trial/welcome-trial.page";
import { SideMenuService } from "@services/side-menu/side-menu.service";
import { LogoutService } from "@services/logout/logout.service";
import { ErrorReportService, IErrorReport } from "@services/error-report/error-report.service";
import { DeleteDataModalPage } from "./pages/delete-data-modal/delete-data-modal.page";
import { appEnterAnimation, appLeaveAnimation } from "./@animations";
import { Severity } from "@sentry/types";
import { environment } from "src/environments/environment";
import { MetasService } from "@services/api/metas/metas.service";
import { SessionExpiredService } from "@services/session-expired/session-expired.service";
import { FirebaseAnalyticsService } from "@services/firebase-analytics.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends BasePageController {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  public showSubmenu: boolean = false;
  public showSubmenu2: boolean = false;

  public syncStatus: BehaviorSubject<boolean>;

  constructor(
    public app: NavController,
    public appService: AppService,
    public platform: Platform,
    public storageService: StorageService,
    public userStoreService: UserStoreService,
    public sentryService: SentryService,
    public amplitudeService: AmplitudeService,
    public inAppBrowserService: InAppBrowserService,
    public networkService: NetworkService,
    public sideMenuService: SideMenuService,
    private estudoService: EstudoService,
    private syncService: SyncService,
    private colorCachingService: ColorCachingService,
    private generalSubjectCachingService: GeneralSubjectCachingService,
    private userSubjectCachingService: UserSubjectCachingService,
    private studyTypeCachingService: StudyTypeCachingService,
    private statisticCachingService: StatisticCachingService,
    private cycleCachingService: CycleCachingService,
    private cycleBlocksCachingService: CycleBlocksCachingService,
    private reviewCachingService: ReviewCachingService,
    private topicCachingService: TopicCachingService,
    private metasService: MetasService,
    private eventsService: EventService,
    private loading: LoadingService,
    private reviewUtils: ReviewUtils,
    private logoutService: LogoutService,
    private errorReport: ErrorReportService,
    private sessionExpiredService: SessionExpiredService,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
    super();
  }

  async ngOnInit() {
    try {
      await this.appService.init(this.routerOutlet);
    } catch (e) {
      console.error(e);
    }

    this.syncStatus = this.syncService.getSyncStatus();
    await this.setRootPage();

    // this.initTranslate();
    // this.registerBackButtonAction();

    this.eventsService.subscribe('reviewProgram:completed', async (review: Review) => {
      if (review && review.sequence === review.totalReviews) {
        const modal = await this.modalCtrl.getTop();
        if (modal) {
          await modal.onDidDismiss();
        }

        this.reviewUtils.showFinishedReviewsDialog(review);
      }
    });

    this.eventsService.subscribe('subscription:inAppPurchase', async () => {
      await this.setRootPage();
    });

    this.eventsService.subscribe('login:showWelcomePage', async () => {
      
      const user = this.userStoreService.user;
      this.sentryService.setUser({email: user.email, id: user.objectId});
      this.amplitudeService.setUserId(user.objectId);
      this.firebaseAnalyticsService.setUserId(user.objectId);

      const showTrial = await this.storageService.get('show-trial');
      let welcomeModal: HTMLIonModalElement | null;

      try {
        await this.syncAll();
      } catch (e) {
        this.errorReport.sendReport({
          sentry: { err: e, tags: { fn: 'login:showWelcomePage' }, level: Severity.Error }
        });
      }
      this.storageService.set('didSyncAll', true);
      
      if (showTrial) {
        welcomeModal = await this.modalCtrl.create({ component: WelcomeTrialPage, cssClass: 'fullscreen modal-transparent' });
        await welcomeModal.present();
        this.storageService.set('show-trial', false);
        await welcomeModal.onDidDismiss();
      }
      
      try {
        const hasMetas = this.metasService.hasMetas();
        if (!hasMetas) {
          this.eventsService.publish('inteview:show');
        }
      } catch (e) {
        this.appService.showSyncErrorDialog(JSON.stringify(e, Object.getOwnPropertyNames(e)));
      }
    });

    // this.app.viewWillEnter.subscribe(view => this.amplitudeProvider.logPageEvent(view));

    // this.app.viewDidEnter.subscribe(view => {
    //   if (view.instance.metaAnalytics) {
    //     if (view.instance.metaAnalytics.title)
    //       this.firebaseAnalytics.setCurrentScreen(view.instance.metaAnalytics.title);
    //   }
    // });

    this.eventsService.subscribe('logout', () => this.handleLogout());
    this.eventsService.subscribe('doSync', () => this.doSync());

    this.syncStatus = this.syncService.getSyncStatus();
  }

  async setRootPage(user?: User) {
    const u = user || (await this.userStoreService.getUser());

    if (u) {

      await this.appService.resolveSessionExpired();
      await this.metasService.initMetas(u);

      const isConnected = this.networkService.isConnected();
      if (isConnected) {
        await this.checkIfIsAccessExpired(u);
        await this.metasService.refreshMetas();

        const preventSync = await this.preventSync();
        const shouldSyncResult = await this.shouldSync(preventSync);
        const hasMetas = this.metasService.hasMetas();

        if (shouldSyncResult) {
          await this.doPartialSync(hasMetas,preventSync);
          const cycleResult = await this.cycleBlocksCachingService.read();
          this.eventsService.publish('synchronized:blocks', cycleResult);
        }

      }

      await this.appService.resolveShowFiveDaysDialog();
    }

    this.checkIfSyncIsNeeded();
  }

  private async checkIfSyncIsNeeded() {
    const statistics = await this.statisticCachingService.read();
    const blocks = await this.cycleBlocksCachingService.read();
    const reviews = await this.reviewCachingService.read();
    const subjects = await this.userSubjectCachingService.read();
    const cycles = await this.cycleCachingService.read();
    const all = [...statistics, ...blocks, ...reviews, ...subjects, ...cycles].map((item) => ({
      hasPendingDelete: item.hasPendingDelete,
      hasPendingWrite: item.hasPendingWrite
    }));

    if (all.length > 0) 
      this.syncService.setSyncStatus(all.some((item) => item.hasPendingWrite || item.hasPendingDelete));
  }

  async preventSync() {
    const [needSyncCycle, needSyncStatistic, needSyncUserSubject, needSyncReview, needSyncCycleBlocks] =
      await Promise.all([
        this.cycleCachingService.needSync(),
        this.statisticCachingService.needSync(),
        this.userSubjectCachingService.needSync(),
        this.reviewCachingService.needSync(),
        this.cycleBlocksCachingService.needSync(),
        this.generalSubjectCachingService.sync(),
        this.colorCachingService.sync(),
        this.studyTypeCachingService.sync()
      ]);
    return { needSyncCycle, needSyncStatistic, needSyncUserSubject, needSyncReview, needSyncCycleBlocks };
  }

  async doPartialSync(
    showLoading = true,
    needSync: {
      needSyncCycle: boolean;
      needSyncUserSubject: boolean;
      needSyncStatistic: boolean;
      needSyncReview: boolean;
      needSyncCycleBlocks: boolean;
    }
  ) {
    this.networkService.executeIfOnline(async () => {
      if (showLoading) await this.loading.show('Sincronizando dados...', false);
      try {
        const syncTask = {
          needSyncStatistic: () => this.statisticCachingService.sync(),
          needSyncUserSubject: () => this.userSubjectCachingService.sync(),
          needSyncReview: () => this.reviewCachingService.sync(),
          needSyncCycleBlocks: () => this.cycleBlocksCachingService.sync()
        };

        if (needSync.needSyncCycle) {
          await this.cycleCachingService.sync();
          needSync.needSyncCycle = false;
        }

        if (needSync.needSyncUserSubject) {
          await this.userSubjectCachingService.sync();
          needSync.needSyncUserSubject = false;
        }

        await this.topicCachingService.sync();

        const tasks_ = Object.keys(needSync)
          .map((key) => {
            const value = needSync[key];
            if (value) {
              return syncTask[key]();
            }
          })
          .filter((v) => {
            if (v) return v;
          });

        await Promise.all(tasks_);
        this.eventsService.publish('synchronized:blocks');

        if (this.userStoreService.user && this.userStoreService.user.cicloAtivo)
          this.estudoService.updateCicloAtivo(
            this.userStoreService.user.objectId,
            this.userStoreService.user.cicloAtivo.objectId
          );
        this.showToastMessage('Sincronização finalizada', 'top');
        this.syncService.setSyncStatus(false, 'doSync');
      } catch (e) {
        this.appService.showSyncErrorDialog(JSON.stringify(e, Object.getOwnPropertyNames(e)));
        this.sentryService.withScope({ fn: 'doPartialSync', needSync: needSync }, e);
        this.userService.logCrashError(
          this.userStoreService.user.objectId,
          'Erro na sincronização: ' + JSON.stringify(e, Object.getOwnPropertyNames(e))
        );
      }
      if (showLoading) await this.loading.dismiss();
    
      await this.syncService.updateLastSync();
    });
  }

  async openDeleteHistory() {
    this.sideMenuService.hiddenSubmenu();
    await this.menuController.close();

    const modal = await this.modalCtrl.create({
      component: DeleteDataModalPage,
      enterAnimation: appEnterAnimation,
      leaveAnimation: appLeaveAnimation
    });

    modal.present();

    const { data } = await modal.onDidDismiss();

    if (data && data.delete) {
      const loading = await this.showLoading();

      const user = this.userStoreService.user;

      try {
        const cycleIds: string[] = (await this.cycleCachingService.read()).map((cycle) => cycle.objectId);
        await this.userService.clearUserData(user, cycleIds);
      } catch (e) {
        await loading.dismiss();
        await this.showAlertError({
          header: 'Erro ao Limpar Histórico',
          message: 'Ocorreu um erro inesperado ao limpar seu histórico de estudos. Por favor, tente novamente.'
        });
        return;
      }

      try {
        await this.clearAllEntities();
        await loading.dismiss();
        await this.syncAll();
        const alertDialog = await this.showAlert({
          header: 'Sucesso ao Limpar Histórico',
          message: 'Histórico de estudos excluído com sucesso.'
        });
        await alertDialog.onDidDismiss();
        this.eventsService.publish('refresh:reviews');
      } catch (err) {
        this.errorReport.sendReport({ sentry: { err, tags: { fn: 'openDeleteHistory' }, level: Severity.Error } });
        await loading.dismiss();
        await this.showAlert({
          header: 'Erro ao Limpar Histórico',
          message: 'Ocorreu um erro inesperado ao limpar seu histórico de estudos. Por favor, tente novamente.'
        });
      }
    }
  }

  async checkIfIsAccessExpired(userData: User) {
    const userId = userData.objectId;

    try {
      const [user, goal] = await Promise.all([
        this.userService.isUserExpired(userId),
        this.userService.getLastGoal(userId).catch((err) => console.log('Sem objetivo'))
      ]);

      const { expired, planoAtual } = user;
      const { objectId, nome, validade } = planoAtual;
      this.amplitudeService.setUserPlan(objectId, expired, validade, nome);

      await this.userStoreService.saveUser(user);

      if (goal) {
        await this.userStoreService.updateUserGoal(goal.objectId, goal.name);
      }
    } catch (err) {
      try {
        const { message } = err;
        if (message === 'Invalid session token') {
          this.sessionExpiredService.execute();
          return;
        }
      } catch (e) {}
    }
  }

  private async syncAll() {
    const loading = await this.showLoading('Sincronizando todos os dados. Aguarde, isso pode levar um tempo.', false);

    const result = await this.doClearAndSync();
    this.eventsService.publish('synchronized:blocks', result[3]);
    await this.syncService.updateLastSync();
    this.syncService.setSyncStatus(false, 'syncAll()');

    await loading.dismiss().catch((e) => console.log(e));
  }

  async doClearAndSync() {
    this.syncService.publishSyncingStatus('running');

    const res = await Promise.all([
      await this.cycleCachingService.sync(),
      await this.userSubjectCachingService.sync(),
      await this.topicCachingService.sync(),
      this.cycleBlocksCachingService.sync(),
      this.statisticCachingService.sync(),
      this.generalSubjectCachingService.sync(),
      this.colorCachingService.sync(),
      this.studyTypeCachingService.sync(),
      this.reviewCachingService.sync(),
      this.metasService.refreshMetas()
    ]);
    this.syncService.publishSyncingStatus('done');
    return res;
  }

  async clearAllEntities() {
    await this.userSubjectCachingService.clear();
    await this.generalSubjectCachingService.clear();
    await this.statisticCachingService.clear();
    await this.studyTypeCachingService.clear();
    await this.colorCachingService.clear();
    await this.cycleCachingService.clear();
    await this.cycleBlocksCachingService.clear();
    await this.topicCachingService.clear();
    await this.reviewCachingService.clear();
    await this.metasService.clear();
  }

  async doSync(showLoading = true, shouldNotifyStudyPlanTab = true) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_SYNC);

    await this.networkService.executeIfOnline(async () => {
      const didSyncAll = await this.storageService.get('didSyncAll');
      if (!didSyncAll) {
        try {
          await this.syncAll();
          await this.storageService.set('didSyncAll', true);
        } catch (e) {
          this.errorReport.sendReportAndShowEmail(e, {
            sentry: {
              err: e,
              tags: {fn: 'doSync:didSyncAll'},
              level: Severity.Error
            }
          })
        }
        return;
      }

      if (showLoading) await this.loading.show('Sincronizando dados...', false);
      try {
        this.syncService.publishSyncingStatus('running', { shouldNotifyStudyPlanTab });

        const resultAll = await Promise.all([
          await this.cycleCachingService.sync(),
          await this.userSubjectCachingService.sync(),
          await this.topicCachingService.sync(),
          this.cycleBlocksCachingService.sync(),
          this.statisticCachingService.sync(),
          this.reviewCachingService.sync()
        ]);
        if (shouldNotifyStudyPlanTab) this.eventsService.publish('synchronized:blocks', resultAll[3]);
        if (this.userStoreService.user && this.userStoreService.user.cicloAtivo)
          this.estudoService.updateCicloAtivo(
            this.userStoreService.user.objectId,
            this.userStoreService.user.cicloAtivo.objectId
          );

        this.showToast({ message: 'Sincronização finalizada', duration: 2000, position: 'top' });
        this.syncService.setSyncStatus(false, 'doSync');
      } catch (e) {
        this.appService.showSyncErrorDialog(JSON.stringify(e, Object.getOwnPropertyNames(e)));
        this.userService.logCrashError(
          this.userStoreService.user.objectId,
          'Erro na sincronização: ' + JSON.stringify(e, Object.getOwnPropertyNames(e))
        );
        this.errorReport.sendReport({ sentry: { err: e, tags: { fn: 'doSync:2' }, level: Severity.Error } });
      }
      if (showLoading) await this.loading.dismiss();
      await this.syncService.updateLastSync();

      this.syncService.publishSyncingStatus('done', { shouldNotifyStudyPlanTab });
    });
  }

  async handleLogout() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_LOGOUT);

    try {
      if (this.networkService.isConnected()) {
        await this.doSync(true, false);
      }

      const syncNeeded = await this.syncStatus.pipe(first()).toPromise();

      if (syncNeeded) {
        const alert = await this.alertCtrl.create({
          backdropDismiss: false,
          header: 'ATENÇÃO!',
          subHeader: 'Perda de Dados',
          message: 'Caso realize o logout, sem realizar a sincronização, haverá perda de dados.',
          buttons: [
            {
              text: 'Sair assim mesmo',
              role: 'confirm'
            },
            {
              text: 'Cancelar',
              role: 'cancel'
            }
          ]
        });

        alert.present();
        const { role } = await alert.onDidDismiss();
        if (role === 'cancel') {
          return;
        }
      }
      await this.loading.show('Saindo...', true);
      await this.logoutService.logout();
      await this.loading.dismiss();

      await this.router.navigate(['welcome-onboarding'], { replaceUrl: true });
    } catch (e) {
      const errorReport: IErrorReport = {
        sentry: {
          err: e,
          tags: { fn: 'handleLogout' },
          level: Severity.Error
        }
      };
      this.errorReport.sendReport(errorReport);
      console.error({ e });
    }
  }

  onModalRateClick() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_RATE_APP_MENU);
    this.sideMenuService.openModalRate();
  }

  async shouldSync(preventSync: any ) : Promise<boolean> {
    const result = Object.keys(preventSync)
    .map((key) => preventSync[key])
    .find((v) => v === true);

    return result; 
  }

  get appVersion() {
    return environment.appVersion;
  }

  get userEmailEncoded() {
    return encodeURIComponent(this.userStoreService?.user?.email)
  }
}
