export enum AmplitudeEventEnum {
  //AD
  AD_REWARD_LOAD = 'AD_reward_load',
  AD_OPEN_ADS = 'AD_open_ads',
  AD_OPEN_ACCEPT_FREEMIUM = 'AD_open_accept_freemium',
  AD_OPEN_PLANS = 'AD_open_plans',
  AD_REWARDED_REWARD = 'AD_rewarded_reward',
  AD_REWARDED_REWARD_ERROR = 'AD_rewarded_rewarderror',
  AD_PLANS_COMPARE = 'AD_plans_compare',
  AD_OPEN_VIDEO = 'AD_open_video',
  AD_INTERSTITIAL_LOAD = 'AD_Interstitial_load',
  AD_REWARD_LIMIT_REACHED = 'AD_reward_limit_reached',
  //BTN
  BT_LOGIN = 'BT_login',
  BT_CHOOSE_PLAN = 'BT_choose_plan',
  BT_CYCLE_CHANGE = 'BT_cycle_change',
  BT_CYCLE_EDIT = 'BT_cycle_edit',
  BT_CYCLE_BLOCK = 'BT_cycle_block',
  BT_CYCLE_CREATE = 'BT_cycle_create',
  BT_CYCLE_MODE_SELECTION = 'BT_cycle_mode_selection',
  BT_CYCLE_AUTOMATIC_TEST_NOT_FOUND = 'BT_cycle_automatic_test_not_found',
  BT_CYCLE_AUTOMATIC_REQUEST_TEST = 'BT_cycle_automatic_request_test',
  BT_DEFINE_STUDY_PLAN = 'BT_define_study_plan',
  BT_LIST_BLOCK = 'BT_list_block',
  BT_TIMER_RECORD = 'BT_timer_record',
  BT_MANUAL_RECORD = 'BT_manual_record',
  BT_NEXT_CHECKOUT_STEP = 'BT_next_checkout_step',
  BT_INFO = 'BT_info',
  BT_REFERRAL = 'BT_referral',
  BT_REFERRAL_SHARE = 'BT_referral_share',
  BT_STATISTICS_MENU = 'BT_statistics_menu',
  BT_STATISTICS_DAY = 'BT_statistics_day',
  BT_STATISTICS_WEEK = 'BT_statistics_week',
  BT_STATISTICS_MONTH = 'BT_statistics_month',
  BT_STATISTICS_YEAR = 'BT_statistics_year',
  BT_STATISTICS_TIME = 'BT_statistics_time',
  BT_STATISTICS_EXERCISES = 'BT_statistics_exercises',
  BT_STATISTICS_PAGES = 'BT_statistics_pages',
  BT_STATISTICS_EFFICIENCY = 'BT_statistics_efficiency',
  BT_WEEKLYSCHEDULE_CREATE = 'BT_weeklyschedule_create',
  BT_HISTORIC_FILTER = 'BT_historic_filter',
  BT_HISTORIC_FILTER_DOFILTER = 'BT_historic_filter_dofilter',
  BT_HISTORIC_DELETE_CONFIRMED = 'BT_historic_delete_confirmed',
  BT_RATE_APP_MENU = 'BT_rate_app_menu',
  BT_REVIEW_ADD = 'BT_review_add',
  BT_REVIEW_ADD_SAVE = 'BT_review_add_save',
  BT_REVIEW_SELECTED = 'BT_review_selected',
  BT_REVIEW_DELETE = 'BT_review_delete',
  BT_ALARM = 'BT_alarm',
  BT_ALARM_TIME_SELECTION = 'BT_alarm_time_selection',
  BT_SAVE_STUDY_RECORD = 'BT_save_study_record',
  BT_HELP = 'BT_help',
  BT_GOALS = 'BT_goals',
  BT_MY_CYCLES = 'BT_my_cycles',
  BT_MY_SUBJECTS_TOPICS = 'BT_my_subjects_topics',
  BT_SUBJECT_ADD = 'BT_subject_add',
  BT_TOPIC_ADD = 'BT_topic_add',
  BT_IMPORT_STUDY_DATA = 'BT_import_study_data',
  BT_NOTIFICATION = 'BT_notification',
  BT_SYNC = 'BT_sync',
  BT_MY_SUBSCRIPTION = 'BT_my_subscription',
  BT_TUTORIAL = 'BT_tutorial',
  BT_FAQ = 'BT_FAQ',
  BT_APP_DETAILS = 'BT_app_details',
  BT_BLOG = 'BT_blog',
  BT_FACEBOOK = 'BT_facebook',
  BT_INSTAGRAM = 'BT_instagram',
  BT_YOUTUBE = 'BT_youtube',
  BT_LOGOUT = 'BT_logout',
  BT_BUY = 'BT_buy',
  BT_SUBMIT_PAYMENT = 'BT_submit_payment',
  BT_OPEN_TUTORIAL = 'BT_open_tutorial',
  BT_START_APP = 'BT_start_app',
  BT_GENERIC_EVENT = 'BT_generic_event',
  BT_SAVE_CUSTOMER_INFO = 'BT_save_customer_info',
  BT_ABOUT_US = 'BT_about_us',
  BT_CONTACT = 'BT_contact',
  BT_NEWSLETTER_PERMISSION = 'BT_newsletter_permission',
  BT_RATE_OPEN_STORE = 'BT_rate_open_store',
  BT_PURCHASE_SUBSCRIPTION = 'BT_purchase_subscription',
  ERR_BT_PURCHASE_SUBSCRIPTION = 'ERR_BT_purchase_subscription',
  BT_PLANS_COMPARE_OPEN_SUBSCRIPTIONS = 'BT_plans_compare_open_subscriptions',
  // EVENT
  EV_SUBSCRIPTION_CONFIRMED = 'EV_subscription_confirmed',
  EV_CYCLE = 'EV_cycle',
  EV_WEEKLYSCHEDULE = 'EV_weeklyschedule',
  EV_INSTALLMENTS_SELECTION = 'EV_installments_selection',
  EV_MY_EXAM_CREATE = 'EV_my_exam_create',
  EV_MY_EXAM_UPDATE = 'EV_my_exam_update',
  EV_CREATE_ACCOUNT = 'EV_create_account',
  // POPUP
  POPUP_CYCLE_BLOCK = 'POPUP_cycle_block',
  POPUP_RECORD_TYPE = 'POPUP_record_type',
  POPUP_ONBOARDING = 'POPUP_onboarding',
  POPUP_ONBOARDING_EVENT = 'POPUP_onboarding_event',
  POPUP_ONBOARDING_EVENT_STEP = 'POPUP_onboarding_step',
  POPUP_ONBOARDING_SPEEDUP_ROBOT = 'POPUP_onboarding_speedup_robot',
  POPUP_INSTALLMENTS = 'POPUP_installments',
  PUPUP_NEWSLETTER_PERMISSION = 'POPUP_newsletter_permission',
  POPUP_CHOOSE_STUDY_PLAN_TYPE = 'POPUP_choose_study_plan_type',
  POPUP_RATE = 'POPUP_rate',
  POPUP_RATED = 'POPUP_rated',
  POPUP_CHOOSE_STUDY_TYPE_MODAL = 'POPUP_choose_study_type_modal',
  POPUP_CHOOSE_STUDY_TYPE_MODAL_DISMISS = 'POPUP_choose_study_type_modal_dismiss',

  // PARSE
  PARSE_FUNCTION = 'PARSE_function',
  // SCREENS
  SC_LOGIN = 'SC_login',
  SC_ONBOARDING_1 = 'SC_onboarding_1',
  SC_ONBOARDING_1_v2 = 'SC_onboarding_1_v2',
  SC_CHECKOUT_PERSONAL_DATA = 'SC_checkout_personal_data',
  SC_CYCLE = 'SC_cycle',
  SC_CYCLE_CREATE = 'SC_cycle_create',
  SC_CYCLE_CREATE_AUTOMATIC = 'SC_cycle_create_automatic',
  SC_CHRONOMETER = 'SC_chronometer',
  SC_STATISTICS = 'SC_statistics',
  SC_STATISTICS_SLIDE = 'SC_statistics_slide',
  SC_HISTORY = 'SC_history',
  SC_REVIEW = 'SC_review',
  SC_REFERRAL = 'SC_referral',
  SC_STUDY_RECORD = 'SC_study_record',
  SC_STUDY_RECORD_INFO = 'SC_study_record_info',
  SC_STUDY_SUMMARY = 'SC_study_summary',
  SC_STUDY_REGISTER_MODAL = 'SC_study_register_modal',
  SC_MY_SUBJECTS_TOPICS = 'SC_my_subjects_topics',
  SC_SUBSCRIPTION_PLANS = 'SC_subscription_plans',
  SC_WELCOME_TRIAL = 'SC_welcome_trial',
  SC_WEEKLYSCHEDULE_CREATE = 'SC_weeklyschedule_create',
  SC_OVERDUE = 'SC_overdue',
  SC_GENERIC = 'SC_generic',
  SC_SESSION_EXPIRED = 'SC_SESSION_EXPIRED',
  // TABS
  TAB_REVIEW_DUE = 'TAB_review_due',
  TAB_REVIEW_DONE = 'TAB_review_done',
  TAB_REVIEW_FUTURE = 'TAB_review_future',
  TAB_REVIEW_TODAY = 'TAB_review_today',
  //
  LOG_SC_OVERDUE = 'LOG_sc_overdue',
  //
  ERR_LOGIN_PAGE_OPS = 'ERR_login_page_ops',
  ERR_SIGNUP_PAGE_OPS = 'ERR_signup_page_ops',
  ERR_WELCOME_PAGE_OPS = 'ERR_welcome_page_ops',
  ERROR_SYNC_POPUP = 'ERR_sync_popup',
  ERROR_CYCLE_OPEN_MODAL_SUBJECT = 'ERR_cycle_open_modal_subject'
}
