import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { AmplitudeService } from '@services/amplitude/amplitude.service';
import { RatingService } from '@services/rating/rating.service';
import { ModalRatedPage } from './modal-rated/modal-rated.page';


@Component({
  selector: 'app-modal-rate',
  templateUrl: './modal-rate.page.html',
  styleUrls: ['./modal-rate.page.scss'],
})
export class ModalRatePage implements OnInit {
  rate: number = 0;
  isLessThenFiveStars: boolean = false;
  customForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private amplitudeService: AmplitudeService,
    private ratingService: RatingService
  ) {}

  get startRating() {
    return this.customForm.get('starRating').value;
  }

  ngOnInit() {
    this.customForm = this.formBuilder.group({
      starRating: [0]
    });
    this.amplitudeService.logEvent(AmplitudeEventEnum.POPUP_RATE);
  }

  ionViewWillLeave() {
    this.ratingService.disableRate();
  }

  async finish() {
    await this.modalCtrl.dismiss();
  }

  async open() {
    await this.modalCtrl.dismiss();
    const ratedApp = await this.modalCtrl.create({
      component: ModalRatedPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        rateValue: this.startRating
      }
    });
    await ratedApp.present();
  }

}
