import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  constructor(private angularFireAnalytics: AngularFireAnalytics) {
    this.angularFireAnalytics.logEvent('init');
  }

  setUserId(userId: string) {
    this.angularFireAnalytics.setUserId(userId);
  }

  setUserProperties(user) {
    const { planId, planName } = user.planoAtual;

    this.angularFireAnalytics.setUserProperties({
      planId: planId || null,
      planName: planName || null
    });
  }

  setUserObjective(objective: string) {
    this.angularFireAnalytics.setUserProperties({ objective });
  }

  logEvent(eventName: string, eventParams?: {[key: string]: any}) {
    this.angularFireAnalytics.logEvent(eventName, eventParams)
  }
}
