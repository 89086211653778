import { Component, OnInit } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import { NavParams} from "@ionic/angular";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import {
  CreditCard,
  WirecardCreditCard,
  PaymentMethod,
} from "@models/CreditCard";
import { MoipService } from "@services/api/moip/moip.service";
import { PagarmeService } from "@services/api/pagarme/pagarme.service";
import { CartaoCreditoService } from "@services/cartao-credito/cartao-credito.service";
import moment from "moment";
import { PlanPeriod, PlanType } from "../shared/plan";
import { SubscriptionService } from "../shared/subscription.service";
import { LoadingService } from "@services/loading/loading.service";
import { IntallmentsDialogDropdownPage } from "./intallments-dialog-dropdown/intallments-dialog-dropdown.page";
import { PostPaymentPage } from "../post-payment/post-payment.page";
import { ModalService } from "@services/modal/modal.service";

@Component({
  selector: "app-checkout-select-payment-method",
  templateUrl: "./checkout-select-payment-method.page.html",
  styleUrls: ["./checkout-select-payment-method.page.scss"],
})
export class CheckoutSelectPaymentMethodPage
  extends BasePageController
  implements OnInit
{
  canAddCoupon = false;
  card: CreditCard = {
    cardNumber: "",
    cvv: "",
    expiration: "",
    expiryMonth: "",
    expiryYear: "",
    holderName: "",
  };

  canPayWithBankSlip = true;
  hasCard = false;
  wirecardCreditCard: WirecardCreditCard = undefined;

  assinante: any;
  assinanteWirecard: any;
  // assinatura: any;
  planSubscription: any;
  planoDeInteresse: any;
  userId: string;
  expirationPlano: string;
  nomePlano: string;
  valorPlano: string;
  vencimentoPlano: string;
  duracaoPlano: string;
  validade: string;
  numInstallments = 0;
  selectedLabel = "";
  readonly PREPAID_DATA = {
    ANNUAL: [
      { numIntallments: 1, amount: 9900, amountPerInstallment: 99.0 },
      { numIntallments: 2, amount: 10042, amountPerInstallment: 50.21 },
      { numIntallments: 3, amount: 10191, amountPerInstallment: 33.97 },
      { numIntallments: 4, amount: 10344, amountPerInstallment: 25.86 },
      { numIntallments: 5, amount: 10500, amountPerInstallment: 21.0 },
      { numIntallments: 6, amount: 10662, amountPerInstallment: 17.77 },
      { numIntallments: 7, amount: 10864, amountPerInstallment: 15.52 },
      { numIntallments: 8, amount: 11032, amountPerInstallment: 13.79 },
      { numIntallments: 9, amount: 11214, amountPerInstallment: 12.46 },
      { numIntallments: 10, amount: 11400, amountPerInstallment: 11.4 },
      { numIntallments: 11, amount: 11583, amountPerInstallment: 10.53 },
      { numIntallments: 12, amount: 11760, amountPerInstallment: 9.8 },
    ],
    QUARTERLY: [
      { numIntallments: 1, amount: 3700, amountPerInstallment: 37.0 },
      { numIntallments: 2, amount: 3753, amountPerInstallment: 18.77 },
      { numIntallments: 3, amount: 3810, amountPerInstallment: 12.7 },
    ],

    MONTHLY: [{ numIntallments: 1, amount: 1990, amountPerInstallment: 19.9 }],
  };
  dropdownItems = [];
  coupon: any;
  couponCode = "";
  couponName: string = null;
  couponType: string = null;
  couponValue = 0;
  showCouponInput = false;
  isCouponValid: boolean = null;
  shouldChangeCard = false;
  isFetchingData = true;
  paymentMethod = PaymentMethod.CREDIT_CARD;
  readonly legendPeriods = {
    MONTHLY: "Mensal",
    QUARTERLY: "Trimestral",
    ANNUAL: "Anual",
  };
  planQuery: {
    planPeriod: PlanPeriod;
    planType: PlanType;
  };

  constructor(
    private cartaoCreditoService: CartaoCreditoService,
    private moip: MoipService,
    private navParams: NavParams,
    private pagarmeService: PagarmeService,
    private subscriptionService: SubscriptionService,
    private loading: LoadingService,
    private modalService: ModalService
  ) {
    super();
    this.userId = this.userStoreService.user.objectId;

    // Preenchido caso venha da tela Dados Pessoais
    this.assinante = this.navParams.get("assinante");
    this.planoDeInteresse = this.navParams.get("planoDeInteresse");
    this.planQuery = this.navParams.get("planQuery");
    this.validade = this.navParams.get("validade");
    this.canPayWithBankSlip = this.planQuery.planPeriod !== PlanPeriod.MONTHLY;
  }

  ngOnInit() {
    
  }

  async ionViewDidEnter() {
    await this.loading.show();
    await this.loadPlanData();
    this.isFetchingData = false;
    console.log({isFetchingData: this.isFetchingData})
    await this.loading.dismiss();
  }

  async openInstallmentsModal() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.POPUP_INSTALLMENTS, {
      planType: this.planQuery.planType,
      planPeriod: this.planQuery.planPeriod,
      default: this.numInstallments,
      items: this.dropdownItems,
    });
    const modal = await this.modalCtrl.create({
      component: IntallmentsDialogDropdownPage,
      cssClass: "modal-transparent",
      componentProps: {
        items: this.dropdownItems,
        defaultValue: this.numInstallments,
      },
    });

    await modal.present();
    modal.onDidDismiss().then(({ data }) => {
      if (data && data.value) {
        this.selectedLabel = data.label.replace(/<[^>]*>/g, "");
        this.numInstallments = parseInt(data.value);
        this.amplitudeService.logEvent(
          AmplitudeEventEnum.EV_INSTALLMENTS_SELECTION,
          {
            planType: this.planQuery.planType,
            planPeriod: this.planQuery.planPeriod,
            value: this.numInstallments,
            label: this.selectedLabel,
          }
        );
      }
    });
  }

  async loadPlanData() {
    if (this.planQuery.planType === PlanType.SUBSCRIPTION)
      this.valorPlano = this.planoDeInteresse.valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    else
      this.valorPlano = (
        this.planQuery.planPeriod === PlanPeriod.MONTHLY
          ? 14.9
          : this.PREPAID_DATA[this.planQuery.planPeriod][0].amount / 100
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });

    const expiryDates = await this.moip.obterDatasValidade();
    if (
      this.userStoreService.user.assinaturaAtual &&
      this.userStoreService.user.assinaturaAtual.pagarmeSubscriptionId
    )
      this.planSubscription = await this.pagarmeService.findSubcription(
        this.userStoreService.user.assinaturaAtual.pagarmeSubscriptionId
      );

    let momentDate;

    const isExpired = moment().diff(moment(this.validade), "days") > 0;
    if (this.planSubscription) {
      const nextInvoice = this.planSubscription.current_period_end;
      if (nextInvoice) {
        momentDate = moment(nextInvoice).format("YYYY-MM-DD");
      }
    }

    if (!isExpired) {
      momentDate = moment(this.validade);
      expiryDates.month = momentDate.clone().add(30, "days").format();
      expiryDates.quarter = momentDate.clone().add(90, "days").format();
      expiryDates.year = momentDate.clone().add(365, "days").format();
    }

    this.nomePlano =
      this.planQuery.planType === PlanType.SUBSCRIPTION
        ? "Assinatura"
        : "Pré-pago";
    this.nomePlano += ` ${this.legendPeriods[this.planQuery.planPeriod]}`;
    this.duracaoPlano = (
      this.planQuery.planPeriod === PlanPeriod.ANNUAL
        ? 365
        : this.planQuery.planPeriod === PlanPeriod.QUARTERLY
        ? 90
        : 30
    ).toString();
    this.vencimentoPlano =
      this.planQuery.planPeriod === PlanPeriod.ANNUAL
        ? expiryDates.year
        : this.planQuery.planPeriod === PlanPeriod.QUARTERLY
        ? expiryDates.quarter
        : expiryDates.month;

    this.expirationPlano = moment(this.vencimentoPlano).format("DD/MM/YYYY");
    this.loadDropDownInstallmentItems();
  }

  private loadDropDownInstallmentItems() {
    if (this.planQuery.planType === PlanType.PREPAID) {
      const data = this.PREPAID_DATA[this.planQuery.planPeriod];
      const max = data[data.length - 1].amount / 100;
      this.dropdownItems = data.map((item) => {
        const amount = item.amount / 100;
        const economy = (max - amount).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
        const amountFormatted = amount.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
        const amountPerInstallmentText =
          item.amountPerInstallment.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        const economyText =
          max - amount > 0
            ? `<span class="blue">(economia de ${economy})</span>`
            : "";
        if (item.numIntallments === 1)
          return {
            label: `${amountFormatted} à vista <span class="blue">(economia de ${economy})</span>`,
            value: 1,
          };
        return {
          label: `${item.numIntallments} x ${amountPerInstallmentText} ${economyText}`,
          value: item.numIntallments,
        };
      });
    } else if (this.planQuery.planType === PlanType.SUBSCRIPTION) {
      if (this.planQuery.planPeriod === PlanPeriod.ANNUAL) {
        this.dropdownItems = [
          {
            label:
              'R$ 79,90 à vista <span class="blue">(economia de R$ 14,90)</span>',
            value: 1,
          },
          { label: "12 x R$ 7,90", value: 12 },
        ];
      } else if (this.planQuery.planPeriod === PlanPeriod.QUARTERLY) {
        this.dropdownItems = [
          {
            label:
              'R$ 27,90 à vista <span class="blue">(economia de R$ 1,80)</span>',
            value: 1,
          },
          { label: "3 x R$ 9,90", value: 3 },
        ];
      }
    }
  }

  private async subscribeRecurringPlan() {
    
    const credit_card = {
      card_holder_name: this.card.holderName,
      card_number: this.card.cardNumber,
      card_cvv: this.card.cvv,
      card_expiration_date: this.card.expiration.replace(/\D/g, ""),
    };

    let plan_id: string = this.planoDeInteresse.id_pagarme;
    if (
      this.planQuery.planPeriod !== PlanPeriod.MONTHLY &&
      this.numInstallments <= 1
    )
      plan_id = this.planoDeInteresse.pagarmePlanIdInCash;

    const subscription = {
      plan_id: plan_id,
      planoEscolhido: this.planoDeInteresse,
      card: credit_card,
      postback_url: "https://estudaqui.back4app.io/pagarme",
      metadata: {
        userId: this.userId,
        couponCode: null,
      },
      expiry_date: this.expirationPlano,
      payment_method:
        this.paymentMethod == PaymentMethod.CREDIT_CARD
          ? "credit_card"
          : "boleto",
      customer: {
        email: this.assinante.email,
        name: this.assinante.fullname,
        document_number: this.assinante.cpf,
        address: {
          street: this.assinante.address.street,
          zipcode: this.assinante.address.zipcode,
          neighborhood: this.assinante.address.district,
          street_number: this.assinante.address.number,
          complementary: this.assinante.address.complement,
        },
        phone: {
          ddd: this.assinante.phone_area_code,
          number: this.assinante.phone_number,
        },
      },
      pagarmeSubscriptionId: null,
    };

    let result;

    try {
      let eventName = "Assinatura";
      if (
        this.planSubscription &&
        this.planSubscription.status !== "canceled"
      ) {
        subscription.pagarmeSubscriptionId =
          this.userStoreService.user.assinaturaAtual.objectId;
        result = await this.pagarmeService.upgradeSubscription(
          subscription,
          subscription.pagarmeSubscriptionId
        );
        eventName = "Upgrade de assinatura";
      } else {
        result = await this.pagarmeService.createSubscription(subscription);
      }
      this.amplitudeService.logEvent(
        AmplitudeEventEnum.EV_SUBSCRIPTION_CONFIRMED,
        {
          subscription_confirmation: result.status,
          subscription_plan: this.nomePlano,
          subscription_payment_method:
            this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP
              ? "boleto"
              : "cartão de crédito",
          subscription_value:
            parseInt(this.valorPlano.replace(/\D/g, "")) / 100,
          planType: this.planQuery.planType,
          planPeriod: this.planQuery.planPeriod,
        }
      );
      // this.firebaseAnalytics.logEvent(eventName, {
      //   email: this.userStoreService.user.username,
      //   plano: this.nomePlano,
      //   valor: parseInt(this.valorPlano.replace(/\D/g, "")) / 100,
      //   compraFinalizada: true,
      //   formaPagamento:
      //     this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP
      //       ? "boleto"
      //       : "cartão de crédito",
      //   status: result.status,
      // });
    } catch (subscriptionException) {
      let message =
        "Ocorreu algum erro ao realizar a assinatura. Por favor, tente novamente.";
      if (
        subscriptionException.message &&
        subscriptionException.message.response
      ) {
        const errorResponse = subscriptionException.message.response;
        if (errorResponse.errors.length > 0)
          message = errorResponse.errors[0].message;
      }
      await this.loading.dismiss();

      this.Swal.fire({
        heightAuto: false,
        title: "Erro ao Assinar Plano",
        html: `<p class="sweet-alert-text indent" style="text-align: left">Por favor, <b>verifique todos os dados digitados</b>, inclusive o endereço e CPF.</p>
        <p class="sweet-alert-text indent" style="text-align: left">Se os dados estiverem corretos, <b>entre em contato com a operadora do cartão</b> para entender o motivo da recusa.</p>
        <br />
        <p class="sweet-alert-text indent" style="text-align: left">Alternativamente, você pode realizar a assinatura <b>ANUAL ou TRIMESTRAL</b> com <b>BOLETO</b>.</p>`,
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "OK",
      });

      return;
    }

    const params = {
      duracaoPlano: this.duracaoPlano,
      expirationPlano: this.expirationPlano,
      firstBuy: false,
      nomePlano: this.nomePlano,
      paymentMethod: this.paymentMethod,
      subscription: result,
    };
    
    const modal = await this.modalCtrl.create({
      component: PostPaymentPage,
      componentProps: {...params}
    })
    await this.loading.dismiss();
    this.modalService.storeModal(modal);
    modal.present();
  }

  private async subscribeToPrepaidPlan() {
    const obj = {
      userId: this.userStoreService.user.objectId,
      address: {
        country: "br",
        state: this.assinante.address.state.toUpperCase(),
        city: this.assinante.address.city,
        neighborhood: this.assinante.address.district,
        street: this.assinante.address.street,
        street_number: this.assinante.address.number,
        zipcode: this.assinante.address.zipcode,
      },
      customer: {
        external_id: this.userStoreService.user.objectId,
        name: this.assinante.fullname.toUpperCase(),
        type: "individual",
        country: "br",
        email: this.assinante.email,
        documents: [
          {
            type: "cpf",
            number: this.assinante.cpf,
          },
        ],
        phone_numbers: [
          `+55${this.assinante.phone_area_code}${this.assinante.phone_number}`,
        ],
        birthday: `${this.assinante.birthdate_year}-${
          this.assinante.birthdate_month < 10
            ? "0" + this.assinante.birthdate_month
            : this.assinante.birthdate_month
        }-${
          this.assinante.birthdate_day < 10
            ? "0" + this.assinante.birthdate_day
            : this.assinante.birthdate_day
        }`,
      },
      card: {
        card_number: this.card.cardNumber,
        card_holder_name: this.card.holderName,
        card_cvv: this.card.cvv,
        card_expiration_date: this.card.expiration.replace(/\D/g, ""),
      },
      numInstallments: this.numInstallments,
      paymentMethod:
        this.paymentMethod == PaymentMethod.CREDIT_CARD
          ? "credit_card"
          : "boleto",
      planPeriod: this.planQuery.planPeriod,
    };

    let result;

    try {
      result = await this.subscriptionService.createPrepaidSubscription(obj);
    } catch (error) {
      await this.loading.dismiss();
      this.Swal.fire({
        heightAuto: false,
        title: "Erro ao Assinar Plano",
        html: `<p class="sweet-alert-text indent" style="text-align: left">Por favor, <b>verifique todos os dados digitados</b>, inclusive o endereço e CPF.</p>
        <p class="sweet-alert-text indent" style="text-align: left">Se os dados estiverem corretos, <b>entre em contato com a operadora do cartão</b> para entender o motivo da recusa.</p>
        <br />
        <p class="sweet-alert-text indent" style="text-align: left">Alternativamente, você pode realizar a assinatura <b>ANUAL ou TRIMESTRAL</b> com <b>BOLETO</b>.</p>`,
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "OK",
      });
      return;
    }

    const params = {
      duracaoPlano: this.duracaoPlano,
      expirationPlano: this.expirationPlano,
      firstBuy: false,
      nomePlano: this.nomePlano,
      paymentMethod: this.paymentMethod,
      subscription: result,
    };
   
    const modal = await this.modalCtrl.create({
      component: PostPaymentPage,
      componentProps: {...params}
    })
    this.modalService.storeModal(modal);

    await this.loading.dismiss();
    modal.present();
  }

  async doSubscription() {
    await this.loading.show();
    
    if (this.paymentMethod === PaymentMethod.CREDIT_CARD) {
      if (
        this.planQuery.planPeriod !== PlanPeriod.MONTHLY &&
        this.numInstallments === 0
      ) {
        await this.loading.dismiss();
        await this.showToastMessage("Selecione o número de parcelas");
        return;
      }

      if (
        !this.card.holderName ||
        !this.card.cardNumber ||
        !this.card.cvv ||
        !this.card.expiration
      ) {
        await this.loading.dismiss();
        await this.showToastMessage(
          "Certifique-se de que preencheu corretamente o formulário"
        );
        return;
      }

      const validations = await this.pagarmeService.validateCard({
        card_holder_name: this.card.holderName,
        card_expiration_date: this.card.expiration.replace(/\D/g, ""),
        card_number: this.card.cardNumber,
        card_cvv: this.card.cvv,
      });

      if (!validations.card_number) {
        await this.loading.dismiss();
        await this.showToastMessage("Número do cartão inválido.");
        return;
      }

      if (!validations.card_holder_name) {
        await this.loading.dismiss();
        await this.showToastMessage("Nome do titular inválido.");
        return;
      }

      if (!validations.card_expiration_date) {
        await this.loading.dismiss();
        await this.showToastMessage(
          "A data de expiração do cartão é inválida."
        );
        return;
      }

      if (!validations.card_cvv) {
        await this.loading.dismiss();
        await this.showToastMessage(
          "O CVV do cartão é inválido. Certifique-se de que informou corretamente."
        );
        return;
      }
    }

    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_SUBMIT_PAYMENT, {
      subscription_plan: this.nomePlano,
      subscription_payment_method:
        this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP
          ? "boleto"
          : "cartão de crédito",
      subscription_value: parseInt(this.valorPlano.replace(/\D/g, "")) / 100,
    });

    if (this.planQuery.planType === PlanType.PREPAID)
      await this.subscribeToPrepaidPlan();
    else await this.subscribeRecurringPlan();
  }

  async subscribePlan() {
    await this.loading.show();
    let isCreditCardValid = false;

    if (this.isPaymentViaCreditCard) {
      isCreditCardValid =
        this.hasCard || this.cartaoCreditoService.isCreditCardValid(this.card);
    }

    const credit_card = {
      card_holder_name: this.card.holderName,
      card_number: this.card.cardNumber,
      card_cvv: this.card.cvv,
      card_expiration_date: this.card.expiration.replace(/\D/g, ""),
    };

    const subscription = {
      plan_id: this.planoDeInteresse.id_pagarme
        .toString()
        .replace("1051458", "1068347"),
      planoEscolhido: this.planoDeInteresse,
      card: credit_card,
      postback_url: "https://estudaqui.back4app.io/pagarme",
      metadata: {
        userId: this.userId,
        couponCode: null,
      },
      expiry_date: this.expirationPlano,
      payment_method:
        this.paymentMethod == PaymentMethod.CREDIT_CARD
          ? "credit_card"
          : "boleto",
      customer: {
        email: this.assinante.email,
        name: this.assinante.fullname,
        document_number: this.assinante.cpf,
        address: {
          street: this.assinante.address.street,
          zipcode: this.assinante.address.zipcode,
          neighborhood: this.assinante.address.district,
          street_number: this.assinante.address.number,
          complementary: this.assinante.address.complement,
        },
        phone: {
          ddd: this.assinante.phone_area_code,
          number: this.assinante.phone_number,
        },
      },
      pagarmeSubscriptionId: null,
    };

    let result;

    try {
      let eventName = "Assinatura";
      if (
        this.planSubscription &&
        this.planSubscription.status !== "canceled"
      ) {
        subscription.pagarmeSubscriptionId =
          this.userStoreService.user.assinaturaAtual.objectId;
        result = await this.pagarmeService.upgradeSubscription(
          subscription,
          subscription.pagarmeSubscriptionId
        );
        eventName = "Upgrade de assinatura";
      } else {
        result = await this.pagarmeService.createSubscription(subscription);
      }
      this.amplitudeService.logEvent(
        AmplitudeEventEnum.EV_SUBSCRIPTION_CONFIRMED,
        {
          subscription_confirmation: result.status,
          subscription_plan: this.nomePlano,
          subscription_payment_method:
            this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP
              ? "boleto"
              : "cartão de crédito",
          subscription_value:
            parseInt(this.valorPlano.replace(/\D/g, "")) / 100,
        }
      );
      // this.firebaseAnalytics.logEvent(eventName, {
      //   email: this.userStoreService.user.username,
      //   plano: this.nomePlano,
      //   valor: parseInt(this.valorPlano.replace(/\D/g, "")) / 100,
      //   compraFinalizada: true,
      //   formaPagamento:
      //     this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP
      //       ? "boleto"
      //       : "cartão de crédito",
      //   status: result.status,
      // });
    } catch (subscriptionException) {
      let message =
        "Ocorreu algum erro ao realizar a assinatura. Por favor, tente novamente.";
      if (
        subscriptionException.message &&
        subscriptionException.message.response
      ) {
        const errorResponse = subscriptionException.message.response;
        if (errorResponse.errors.length > 0)
          message = errorResponse.errors[0].message;
      }
      await this.loading.dismiss();
      this.Swal.fire({
        heightAuto: false,
        title: "Erro ao Assinar Plano",
        html: `<p class="sweet-alert-text indent" style="text-align: left">Por favor, <b>verifique todos os dados digitados</b>, inclusive o endereço e CPF.</p>
        <p class="sweet-alert-text indent" style="text-align: left">Se os dados estiverem corretos, <b>entre em contato com a operadora do cartão</b> para entender o motivo da recusa.</p>
        <br />
        <p class="sweet-alert-text indent" style="text-align: left">Alternativamente, você pode realizar a assinatura <b>ANUAL ou TRIMESTRAL</b> com <b>BOLETO</b>.</p>`,
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "OK",
      });

      return;
    }

    const params = {
      duracaoPlano: this.duracaoPlano,
      expirationPlano: this.expirationPlano,
      firstBuy: false,
      nomePlano: this.nomePlano,
      paymentMethod: this.paymentMethod,
      subscription: result,
    };
    const modal = await this.modalCtrl.create({
      component: PostPaymentPage,
      componentProps: {...params}
    })

    await modal.present();

    this.modalService.storeModal(modal);

    await this.loading.dismiss();
  }

  async alterarAssinatura(subscription) {
    try {
      if (this.shouldChangeCard) {
        const hasUpdated = await this.editar();

        if (!hasUpdated) {
          this.alertCtrl
            .create({
              header: "<h6>Alteração de Cartão de Crédito</h6>",
              message:
                '<p class="msg-assinatura">Ocorreu um erro ao tentar alterar o cartão de crédito. Por favor, tente novamente.</p>',
              backdropDismiss: false,
              buttons: [
                {
                  text: "OK",
                  handler: () => {},
                },
              ],
            })
            .then((alertRes) => alertRes.present());

          return;
        }
      }

      const succededSubscription = await this.moip.upgradeAssinatura({
        upgrade: subscription,
      });
      const params = {
        duracaoPlano: this.duracaoPlano,
        expirationPlano: this.expirationPlano,
        firstBuy: false,
        nomePlano: this.nomePlano,
        paymentMethod: this.paymentMethod,
        subscription: succededSubscription,
      };

      //await this.navCtrl.push('PostPaymentPage', params);
      const modal = await this.modalCtrl.create({
        component: PostPaymentPage,
        componentProps: {...params}
      });
      this.modalService.storeModal(modal);
    } catch (err) {
      this.showToastMessage(
        "Ocorreu um erro ao fazer upgrade do plano. Por favor, tente novamente."
      );
    }
  }

  async criarAssinante() {
    const card =
      this.paymentMethod === PaymentMethod.CREDIT_CARD
        ? {
            holder_name: this.card.holderName,
            number: this.card.cardNumber,
            expiration_month: this.card.expiryMonth,
            expiration_year: this.card.expiryYear,
          }
        : undefined;

    const assinante = {
      birthdate_day: this.assinante.birthdate_day,
      birthdate_month: this.assinante.birthdate_month,
      birthdate_year: this.assinante.birthdate_year,
      code: this.assinante.code,
      cpf: this.assinante.cpf,
      email: this.assinante.email,
      fullname: this.assinante.fullname,
      phone_area_code: this.assinante.phone_area_code,
      phone_number: this.assinante.phone_number,
      address: {
        city: this.assinante.address.city,
        complement: this.assinante.address.complement,
        country: this.assinante.address.country,
        district: this.assinante.address.district,
        number: this.assinante.address.number,
        state: this.assinante.address.state,
        street: this.assinante.address.street,
        zipcode: this.assinante.address.zipcode,
      },
      card: card,
    };

    try {
      await this.moip.criarAssinante({ assinante });
      return true;
    } catch (err) {
      this.showToastMessage(
        "Ocorreu um erro ao criar o assinante. Por favor, tente novamente."
      );
      return false;
    }
  }

  async criarAssinatura() {
    try {
      const couponCode = this.isCouponValid ? this.couponCode : null;

      if (this.shouldChangeCard) {
        const hasUpdated = await this.editar();

        if (!hasUpdated) {
          this.alertCtrl
            .create({
              header: "<h6>Alteração de Cartão de Crédito</h6>",
              message:
                '<p class="msg-assinatura">Ocorreu um erro ao tentar alterar o cartão de crédito. Por favor, tente novamente.</p>',
              backdropDismiss: false,
              buttons: [
                {
                  text: "OK",
                  handler: () => {},
                },
              ],
            })
            .then((alertRes) => alertRes.present());

          return;
        }
      }

      const succededSubscription = await this.moip.criarAssinatura({
        assinatura: {
          customer_id: this.assinante.code,
          plan_id_moip: this.planoDeInteresse.id_moip,
          plan_id: this.planoDeInteresse.objectId,
          expiry_date: this.vencimentoPlano,
          coupon_code: couponCode,
          payment_method: this.paymentMethod,
        },
      });

      const params = {
        duracaoPlano: this.duracaoPlano,
        expirationPlano: this.expirationPlano,
        firstBuy: true,
        nomePlano: this.nomePlano,
        paymentMethod: this.paymentMethod,
        subscription: succededSubscription,
      };

     const modal = await this.modalCtrl.create({
        component: PostPaymentPage,
        componentProps: {...params}
      });

      modal.present();
      this.modalService.storeModal(modal);
    } catch (err) {
      this.showToastMessage(
        "Ocorreu um erro ao criar a assintura. Por favor, tente novamente."
      );
    }
  }

  async editar() {
    await this.loading.show();

    // Always encrypt credit card data before send to server
    const isCreditCardValid = this.cartaoCreditoService.isCreditCardValid(
      this.card
    );

    if (isCreditCardValid) {
      const credit_card = {
        holder_name: this.card.holderName,
        number: this.card.cardNumber,
        expiration_month: this.card.expiryMonth,
        expiration_year: this.card.expiryYear,
      };

      try {
        await this.moip.alterarCartao({
          card: credit_card,
          code: this.userId,
        });
        return true;
      } catch (err) {
        return false;
      }
    }

    await this.loading.dismiss();
  }

  toggleCoupon() {
    this.showCouponInput = !this.showCouponInput;
  }

  changeCard() {
    this.alertCtrl
      .create({
        header: "<h6>Alterar Cartão de Cŕedito</h6>",
        message:
          '<p class="msg-assinatura">Ao confirmar, os dados do cartão exibidos serão apagados e você poderá inserir um novo cartão para a assinatura.</p>',
        backdropDismiss: false,
        buttons: [
          {
            text: "CANCELAR",
            handler: () => {},
          },
          {
            text: "OK",
            handler: () => {
              this.card.brand = null;
              this.card.cardNumber = "";
              this.card.expiration = "";
              this.card.expiryMonth = "";
              this.card.expiryYear = "";
              this.card.holderName = "";
              this.hasCard = false;
              this.shouldChangeCard = true;
            },
          },
        ],
      })
      .then((alertRes) => alertRes.present());
  }

  resetCoupon() {
    this.isCouponValid = null;
    this.couponName = null;
    this.couponType = null;
    this.couponValue = 0;
    this.valorPlano = this.planoDeInteresse.valor.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  private isEmpty(field: string): boolean {
    return !field || field.length == 0;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
    this.numInstallments = 0;
  }

  get isPaymentViaCreditCard(): boolean {
    return this.paymentMethod === PaymentMethod.CREDIT_CARD;
  }

  get urlBankSlipImage() {
    return this.isPaymentViaCreditCard
      ? "assets/imgs/payment/bank_slip.png"
      : "assets/imgs/payment/bank_slip_active.png";
  }

  getPaymentMethod() : typeof PaymentMethod {
    return PaymentMethod;
  }
}
