import { Severity } from '@sentry/angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { AlertController, Platform } from '@ionic/angular';
import { LoadingService } from '@services/loading/loading.service';
import { AmplitudeService } from '@services/amplitude/amplitude.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { NetworkService } from '@services/utils/network.service';
import { GooglePurchaseService } from '@services/google-purchase/google-purchase.service';
import { ANDROID_PLAN_IDS } from '@app/subscriptions/shared/android-plans';
import { ErrorReportService } from '@services/error-report/error-report.service';
import { WebUtils } from '@app/util/WebUtils';
import { FirebaseAnalyticsService } from '@services/firebase-analytics.service';


@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {
  public purchaseEvent$: Subject<PurchaseEvent> = new Subject<PurchaseEvent>();
  public store: any;
  public ownedProduct: any;
  public receipt: any;
  constructor(
    private alertController: AlertController,
    private loading: LoadingService,
    private googlePurchaseService: GooglePurchaseService,
    private userStoreService: UserStoreService,
    private amplitudeService: AmplitudeService,
    private networkService: NetworkService,
    private platform: Platform,
    private errorReport: ErrorReportService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {}

  async init() {
    return new Promise((resolve, reject) => {
      this.platform
        .ready()
        .then(async () => {
          if (!WebUtils.isWeb()) {
            this.store = new window.CdvPurchase.Store();
            this.registerProducts();
            this.once();
            await this.store.initialize(['android-playstore']);
            this.store.update();
            resolve(true);
          } else {
            resolve(true);
          }
        })
        .catch((e) => reject(e));
    });
  }
  private async once() {
    const storeSubscriptionEvent = this.store.when();

    this.store.error((err) => {
      this.purchaseEvent$.next('error');
      this.errorAlert(err);
    });

    storeSubscriptionEvent.approved(async (product: any) => {
      console.log('approved');
      this.purchaseEvent$.next('approved');
      product.finish();
    });

    storeSubscriptionEvent.receiptUpdated(async (receipt : Receipt) => {
      this.receipt = receipt;
        const {  transactions } = receipt;

        const transaction = transactions.find((t)=> t.products.find((p)=> this.store.owned(p.id)));
        this.ownedProduct = await this.getProducts().then((p)=> p.find((pp)=> pp.owned));

        if(transaction) {
        this.purchaseEvent$.next('owned');
        const u = this.userStoreService.user || (await this.userStoreService.getUser());

        try {
          if (receipt.platform === 'android-playstore' && u && this.networkService.isConnected()) {
            this.purchaseEvent$.next('persist-started');
            await this.resolveOwnedAndroid(u, receipt.transactions[0].nativePurchase);
            this.purchaseEvent$.next('persist-ended');
          }
        } catch (e) {
          this.loading.dismiss();
          this.errorAlert(e);
          this.purchaseEvent$.next('persist-ended');
          return;
        }  
      }
    
    });
  }


  async getProducts() {
    const products: any[] = this.store.products;
    return products.map((p) => {
      const offer = p.offers[0];
      const pricingPhases = offer.pricingPhases[0];
      const { currency, price, priceMicros } = pricingPhases;
      return {
        id: p.id,
        title: p.title,
        description: p.description,
        currency,
        price,
        priceMicros,
        owned: p.owned,
        offer
      };
    });
  }

  getReceipts() : any[]{
    return this.store.localReceipts;
  }

  async order(offer: any, additionalData?: any) {
    return this.store.order(offer, additionalData);
  }

  private async resolveOwnedAndroid(user, transaction: any) {
    const { receipt } = transaction;
    const receiptObject = JSON.parse(receipt);
    const userId = user ? user.objectId : null;
    const response = await this.googlePurchaseService.purchase({ userId, ...receiptObject });
    const { wasCreated, subscription } = response;
    if (wasCreated) {
      const { plano, amount, fatura_status } = subscription;
      const { nome } = plano;
      this.triggerAnalyticsEvent(nome, amount, fatura_status);
    }
  }

  private triggerAnalyticsEvent(nome, amount, fatura_status) {
    this.triggerAmplitudeSubscriptionConfirmed(nome, amount, fatura_status);

  }

  private triggerFirebaseAnalyticsEvent(nome, amount) {
    this.firebaseAnalytics.logEvent('purchase', {nome, amount});
  }

  private triggerAmplitudeSubscriptionConfirmed(planName, amount, status) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.EV_SUBSCRIPTION_CONFIRMED, {
      subscription_confirmation: status,
      subscription_plan: planName,
      subscription_payment_method: 'google',
      subscription_value: amount
    });
  }

  async presentAlert(title, message) {
    const alert = await this.alertController.create({
      header: title,
      message
    });

    alert.present();
  }

  errorAlert(err) {
    // const error = JSON.stringify(e, Object.getOwnPropertyNames(e));

    // const userEmail: string = this.userStoreService.user?.email ?? '';
    // const subject: string = encodeURIComponent(
    //   `Erro ao realizar sua compra ${environment.appVersion} ${encodeURIComponent(userEmail)}`
    // );
    // const body: string = encodeURIComponent(`Houve um erro durante a compra: ${error}`);
    // Swal.fire({
    //   heightAuto: false,
    //   allowEnterKey: false,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   confirmButtonText: 'ENTENDI',
    //   confirmButtonColor: '#3085d6',
    //   showCancelButton: false,
    //   title: 'Alerta',
    //   html: `<p class="sweet-alert-text">Ops! Houve um erro ao realizar sua compra.</p>
    //   <p class="sweet-alert-text">Se o erro persistir nos avise <a href="mailto:suporte@estudaqui.com?subject=${subject}&body=${body}" class="msg-email">clicando aqui</a>.</p>`
    // });

    try {
      this.errorReport.sendReport({ sentry: { err, tags: { fn: 'purchase_error' }, level: Severity.Error }, amplitude: {event: 'purchase_error', data: err}});
    } catch (e) {
    }


  }

  registerProducts() {
    const type = 'paid subscription';
    const platform = 'android-playstore';
    this.store.register([
      {
        id: ANDROID_PLAN_IDS.ANUAL_PLAN_SUBSCRIPTION,
        type,
        platform
      },
      {
        id: ANDROID_PLAN_IDS.MONTLHY_PLAN_SUBSCRIPTION,
        type,
        platform
      },
      {
        id: ANDROID_PLAN_IDS.QUARTERLY_PLAN_SUBSCRIPTION,
        type,
        platform
      }
    ]);
  }
}
type PurchaseEvent =
  | 'error'
  | 'cancelled'
  | 'approved'
  | 'owned'
  | 'downloading'
  | 'downloaded'
  | 'loaded'
  | 'invalid'
  | 'persist-started'
  | 'persist-ended'
  | 'persist-error';


  export interface Receipt {
    transactions?: (TransactionsEntity)[] | null;
    platform: string;
    purchaseToken: string;
    orderId: string;
  }
  export interface TransactionsEntity {
    className: string;
    transactionId: string;
    state: string;
    products?: (ProductsEntity)[] | null;
    platform: string;
    nativePurchase: NativePurchase;
    purchaseId: string;
    purchaseDate: string;
    isPending: boolean;
    isAcknowledged: boolean;
    renewalIntent: string;
  }
  export interface ProductsEntity {
    id: string;
  }
  export interface NativePurchase {
    orderId: string;
    packageName: string;
    productId: string;
    purchaseTime: number;
    purchaseState: number;
    purchaseToken: string;
    quantity: number;
    autoRenewing: boolean;
    acknowledged: boolean;
    productIds?: (string)[] | null;
    getPurchaseState: number;
    developerPayload: string;
    accountId: string;
    profileId: string;
    signature: string;
    receipt: string;
  }
  