import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
// import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { NavController } from '@ionic/angular';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { EventService } from '@services/event-service/event.service';
import { InAppPurchaseService } from '@services/in-app-purchase/in-app-purchase.service';
import { LoadingService } from '@services/loading/loading.service';

@Component({
  selector: 'app-checkout-select-android-plan',
  templateUrl: './checkout-select-android-plan.page.html',
  styleUrls: ['./checkout-select-android-plan.page.scss']
})
export class CheckoutSelectAndroidPlanPage extends BasePageController implements OnInit {
  products: any[] = [];
  subscription: Subscription;
  purchasePressed: boolean;

  // products: any[] = [
  //   {
  //     id: "365_annual_subs_estudaqui",
  //     title: "Anual",
  //     description: "Cobrado R$97,99/ano. Desconto de 54%.",
  //     price: "97.90 R$",
  //     priceMicros: "97900000",
  //   },
  //   {
  //     id: "90_quarter_subs_estudaqui",
  //     title: "Trimestral",
  //     description: "Cobrado R$39,99/trimestre.",
  //     price: "R$ 39.90",
  //     priceMicros: "39900000",
  //   },
  //   {
  //     id: "30_monthly_subs_estudaqui",
  //     title: "Mensal",
  //     description: "",
  //     price: "R$ 17.90",
  //     priceMicros: "17990000",
  //   },
  // ];
  constructor(
    private ref: ChangeDetectorRef,
    private loading: LoadingService,
    private events: EventService,
    private userStore: UserStoreService,
    private inAppPurchaseService: InAppPurchaseService,
    public navCtrl: NavController
  ) {
    super();
  }

  async ngOnInit() {
    this.products = [];
    this.products = await this.inAppPurchaseService.getProducts();
    this.products.forEach((p)=> console.log(p.description));
    if (this.products.length < 1) {
      await this.inAppPurchaseService.init();
      this.products = await this.inAppPurchaseService.getProducts();
    }

    this.ref.detectChanges();

  }

  ionViewDidEnter() {
    this.purchasePressed = false;
    this.amplitudeService.logEvent(AmplitudeEventEnum.SC_SUBSCRIPTION_PLANS, { products_size: this.products.length });
    this.subscription = this.inAppPurchaseService.purchaseEvent$.subscribe((value) => {
      if (this.purchasePressed) {
        if (value === 'persist-ended') {
          this.loading.dismiss();
          this.events.publish('subscription:inAppPurchase');
          this.dismiss();
          //this.navCtrl.setRoot(MainPage);
        } else if (value === 'persist-started') {
          this.loading.show(null, false);
        } else if (value === 'persist-error' || value === 'cancelled') {
          this.loading.dismiss();
        }
      }
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  calculateMonthlyAmount(id: string, priceMicros: number) {
    let divider = 1;

    const amount = priceMicros / 1000000;

    if (id === '365_annual_subs_estudaqui') {
      divider = 12;
    } else if (id === '90_quarter_subs_estudaqui') {
      divider = 3;
    }

    return (amount / divider).toFixed(2);
  }

  purchase(product: any ) {
    if (product.owned) {
      this.showToastMessage('Você já possui o plano selecionado.');
      return;
    }
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_PURCHASE_SUBSCRIPTION, { platform: 'android', product })

    const userEmail = this.userStore.user.email;
    this.loading.show(null, false);

    this.inAppPurchaseService.order(product.offer, { userEmail }).then(
      () => {
        this.loading.dismiss();
        this.purchasePressed = true;
      },
      (error) => {
        this.amplitudeService.logEvent(AmplitudeEventEnum.ERR_BT_PURCHASE_SUBSCRIPTION, { platform: 'android', product, error })
        this.loading.dismiss();
        const userId = this.userStoreService.user?.objectId ?? '';
        const subject: string = encodeURIComponent(
          `Erro ao realizar sua compra ${environment.appVersion} -${encodeURIComponent(userEmail)}`
        );
        const body: string = encodeURIComponent(`Houve um erro durante a compra: ${error} ${product}`);
        this.Swal.fire({
          heightAuto: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'ENTENDI',
          confirmButtonColor: '#3085d6',
          showCancelButton: false,
          title: 'Alerta',
          html: `<p class="sweet-alert-text">Ops! Houve um erro ao realizar sua compra.</p>
          <p class="sweet-alert-text">Se o erro persistir nos avise <a href="mailto:suporte@estudaqui.com?subject=${subject}&body=${body}" class="msg-email">clicando aqui</a>.</p>`
        });
      }
      
    );
  }

  restore() {
   // this.store.refresh();
  }

  refresh() {
    // this.store.refresh();
    //this.products = this.store.products;
  }

  presentAlert(title, message) {
    this.showAlertError({ header: title, message });
  }

  errorAlert(error) {
    const userEmail = this.userStoreService.user?.email ?? '';
    const subject: string = encodeURIComponent(`Erro ao realizar sua compra ${environment.appVersion} - ${encodeURIComponent(userEmail)}`);
    const body: string = encodeURIComponent(`Houve um erro durante a compra: ${error}`);
    this.Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'ENTENDI',
      confirmButtonColor: '#3085d6',
      showCancelButton: false,
      title: 'Alerta',
      html: `<p class="sweet-alert-text">Ops! Houve um erro ao realizar sua compra.</p>
      <p class="sweet-alert-text">Se o erro persistir nos avise <a href="mailto:suporte@estudaqui.com?subject=${subject}&body=${body}" class="msg-email">clicando aqui</a>.</p>`
    });
  }

  sortProducts() {
    this.products.sort((a, b) => b.priceMicros - a.priceMicros);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
