import { Component, OnInit } from "@angular/core";
import * as _ from "underscore";
import { BasePageController } from "@app/pages/BasePage";
import { NavParams } from "@ionic/angular";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { User } from "@models/User";
import { PlanPeriod, PlanType } from "../shared/plan";
import { SubscriptionService } from "../shared/subscription.service";
import { Util } from "@app/util/util";
import { PhoneService } from "@app/@services/api/phone/phone.service";
import moment from "moment";
import cep from 'cep-promise';
import { DialogTestPage } from "@app/dialog-test/dialog-test.page";
import { CheckoutSelectPaymentMethodPage } from "../checkout-select-payment-method/checkout-select-payment-method.page";
import { ModalService } from "@services/modal/modal.service";

@Component({
  selector: "app-checkout-personal-data",
  templateUrl: "./checkout-personal-data.page.html",
  styleUrls: ["./checkout-personal-data.page.scss"],
})
export class CheckoutPersonalDataPage
  extends BasePageController
  implements OnInit
{
  message: string;
  messages: any = [];
  contador = 1;
  pergunta: any = [];
  isBtnShow = false;
  isIphoneX = false;
  user: User;
  userId: string;
  planoDeInteresse: any;

  assinante = {
    birthdate_day: 0,
    birthdate_month: 0,
    birthdate_year: 0,
    code: "",
    cpf: "",
    email: "",
    fullname: "",
    phone_area_code: "",
    phone_number: "",
    address: {
      city: "",
      complement: "",
      country: "BRA",
      district: "",
      number: "",
      state: "",
      street: "",
      zipcode: "",
    },
  };

  fullname: string;
  cpf: string;
  phone: string;
  email: string;
  birthdate: string;
  birthdateAux: string;
  cep: string;
  states: Array<any>;
  selectedState: { abbreviation: string; name: string } = {
    abbreviation: undefined,
    name: undefined,
  };
  city: string;
  district: string;
  street: string;
  number: string;
  complement: string;

  planQuery: {
    planPeriod: PlanPeriod;
    planType: PlanType;
  };
  constructor(
    private navParams: NavParams,
    private phoneService: PhoneService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit() {
    this.loadStates();
    this.user = this.userStoreService.user;
    this.amplitudeService.logEvent(
      AmplitudeEventEnum.SC_CHECKOUT_PERSONAL_DATA
    );
  }

  async ionViewWillEnter() {
    const loading = await this.showLoading();

    this.planQuery = this.navParams.get("planQuery");
    console.log({planquery: this.planQuery})
    if (this.planQuery && this.planQuery.planType === PlanType.SUBSCRIPTION) {
      this.subscriptionService
        .queryPlanByTypeAndPeriod(
          this.planQuery.planType,
          this.planQuery.planPeriod
        )
        .then((result) => (this.planoDeInteresse = result.plan));
    }

    await this.loadUserData();

    await loading.dismiss();
  }

  get birthDayDisplay() {
    if (!this.birthdate) return '';
    return this.birthdate
      .split('-')
      .reverse()
      .join('/');
  }

  async openTimePicker() {
    const dateDialog = await this.modalCtrl.create({
      component: DialogTestPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        type: "date",
        date: this.birthdate,
      },
    });

    dateDialog.present();

    dateDialog.onDidDismiss().then((response) => {
      if (response && response.data) {
        const { data } = response;
        this.birthdate = data.date;
        console.log({data, birthdate: this.birthdate})
      }
    });
  }

  async findAddressByCEP() {
    if (this.cep.length === 8) {
      const loading = await this.showLoading("Buscando CEP");
      try {
        const address = await cep(this.cep);
        this.city = address.city;
        this.district = address.neighborhood;
        this.selectedState = _.find(this.states, {
          abbreviation: address.state,
        });
        this.street = address.street;
        this.cep = address.cep;
      } catch (err) {
        this.toast("CEP não encontrado. Por favor, continue manualmente.");
      }

      await loading.dismiss();
    }
  }

  async salvar() {
    if (this.isUserDataValid()) {
      const loading = await this.showLoading("Salvando dados...");
    
      const data = moment(this.birthdate, ["MM-DD-YYYY", "YYYY-MM-DD"]);
     
      this.assinante.birthdate_day = data.date();
      this.assinante.birthdate_month = data.month() + 1;
      this.assinante.birthdate_year = data.year();
      console.log({assinate: this.assinante})
      this.assinante.code = this.user.objectId;
      this.assinante.cpf = this.cpf.replace(/\D/g, "");
      this.assinante.email = this.email;
      this.assinante.fullname = this.fullname;
      this.assinante.phone_area_code = this.phoneService.getPhoneAreaCode(
        this.phone
      );
      this.assinante.phone_number = this.phoneService.getPhoneNumber(
        this.phone
      );
      this.assinante.address.city = this.city;
      this.assinante.address.complement = this.complement;
      this.assinante.address.district = this.district;
      this.assinante.address.number = this.number;
      this.assinante.address.state = this.selectedState.abbreviation;
      this.assinante.address.street = this.street;
      this.assinante.address.zipcode = this.cep;

      const params = {
        assinante: this.assinante,
        planoDeInteresse: this.planoDeInteresse,
        validade: this.user.validade,
        userId: this.userId,
        planQuery: this.planQuery,
      };

      try {
        await this.userService.persistAssinante({ assinante: this.assinante });
        if (this.planQuery) {
          this.amplitudeService.logEvent(
            AmplitudeEventEnum.BT_NEXT_CHECKOUT_STEP,
            {
              origin: "checkout_personal_data",
            }
          );
                        
          const modal = await this.modalCtrl.create({
            component: CheckoutSelectPaymentMethodPage,
            componentProps: {...params}
          })
          this.modalService.storeModal(modal);
          modal.present();

        } else {
          this.amplitudeService.logEvent(
            AmplitudeEventEnum.BT_SAVE_CUSTOMER_INFO
          );
          this.toast("Dados pessoais salvos com sucesso.");
        }
      } catch (err) {
        console.log({ err });
        this.toast(
          "Ocorreu um erro ao tentar salvar os dados do cliente. Por favor, tente novamente."
        );
      }

      await loading.dismiss();
    }
  }

  isUserDataValid() {
    /* Validações de campo vazio */
    if (this.isEmpty(this.fullname)) {
      this.toast("O nome é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.cpf)) {
      this.toast("O CPF é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.phone)) {
      this.toast("O telefone é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.email)) {
      this.toast("O email é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.birthdate)) {
      this.toast("A data de nascimento é obrigatória.");
      return false;
    }

    if (this.isEmpty(this.cep)) {
      this.toast("O CEP é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.selectedState.name)) {
      this.toast("O estado é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.city)) {
      this.toast("A cidade é obrigatória.");
      return false;
    }

    if (this.isEmpty(this.district)) {
      this.toast("O bairro é obrigatório.");
      return false;
    }

    if (this.isEmpty(this.street)) {
      this.toast("A rua é obrigatória.");
      return false;
    }

    if (this.isEmpty(this.number)) {
      this.toast("O número é obrigatório.");
      return false;
    }

    /* Validações de corretude */
    if (!Util.validarCPF(this.cpf)) {
      this.toast("Seu CPF parece incorreto. Por favor, digite-o novamente.");
      this.cpf = "";
      return false;
    }

    if (!Util.validateEmail(this.email)) {
      this.toast("Seu e-mail parece incorreto. Por favor, digite-o novamente.");
      this.email = "";
      return false;
    }

    if (!this.phoneService.isPhoneValid(this.phone)) {
      this.toast(
        "Seu telefone parece incorreto. Por favor, digite-o novamente."
      );
      this.phone = "";
      return;
    }

    return true;
  }

  loadStates() {
    this.states = [
      { abbreviation: "AC", name: "Acre" },
      { abbreviation: "AL", name: "Alagoas" },
      { abbreviation: "AM", name: "Amazonas" },
      { abbreviation: "AP", name: "Amapá" },
      { abbreviation: "BA", name: "Bahia" },
      { abbreviation: "CE", name: "Ceará" },
      { abbreviation: "DF", name: "Distrito Federal" },
      { abbreviation: "ES", name: "Espírito Santo" },
      { abbreviation: "GO", name: "Goiás" },
      { abbreviation: "MA", name: "Maranhão" },
      { abbreviation: "MG", name: "Minas Gerais" },
      { abbreviation: "MS", name: "Mato Grosso do Sul" },
      { abbreviation: "MT", name: "Mato Grosso" },
      { abbreviation: "PA", name: "Pará" },
      { abbreviation: "PB", name: "Paraíba" },
      { abbreviation: "PE", name: "Pernambuco" },
      { abbreviation: "PI", name: "Piauí" },
      { abbreviation: "PR", name: "Paraná" },
      { abbreviation: "RJ", name: "Rio de Janeiro" },
      { abbreviation: "RN", name: "Rio Grande do Norte" },
      { abbreviation: "RO", name: "Rondônia" },
      { abbreviation: "RR", name: "Roraima" },
      { abbreviation: "RS", name: "Rio Grande do Sul" },
      { abbreviation: "SC", name: "Santa Catarina" },
      { abbreviation: "SE", name: "Sergipe" },
      { abbreviation: "SP", name: "São Paulo" },
      { abbreviation: "TO", name: "Tocantins" },
    ];
  }

  async loadUserData() {
    this.fullname = this.user.fullname;
    this.cpf = this.user.cpf;
    this.email = this.user.username;

    if (this.user.phone_area_code && this.user.phone_number) {
      this.phone = `(${this.user.phone_area_code}) ${this.user.phone_number}`;
    }

    if (
      this.user.birthdate_day &&
      this.user.birthdate_month &&
      this.user.birthdate_year
    ) {
      this.birthdate = this.formatDate(
        this.user.birthdate_day,
        this.user.birthdate_month,
        this.user.birthdate_year
      );
      this.birthdateAux = this.birthdate;
    }

    this.cep = this.user.address_zipcode;

    if (this.user.address_state) {
      this.selectedState = _.find(this.states, {
        abbreviation: this.user.address_state,
      });
    }

    this.city = this.user.address_city;
    this.district = this.user.address_district;
    this.street = this.user.address_street;
    this.number = this.user.address_number;
    this.complement = this.user.address_complement;
  }

  formatDate(day: number, month: number, year: number): string {
    return `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  }

  isEmpty(field: string): boolean {
    return !field || field.length == 0 || field.length == 0;
  }


  toast(message: string) {
    this.toastController
      .create({
        message: message,
        duration: 3000,
        position: "bottom",
      })
      .then((toast) => toast.present());
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
