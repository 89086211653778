import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Parse from 'parse';
import { NetworkService, ConnectionError } from '../../utils/network.service';
import { AmplitudeEventEnum } from 'src/app/models/AmplitudeEventEnum';
import { AmplitudeService } from '../../amplitude/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class ParseService {
  constructor(private networkService: NetworkService, private amplitudeService: AmplitudeService) {
    Parse.initialize(environment.parseAppId, environment.parseJsId);
    (Parse as any).serverURL = environment.serverURL;
  }

  subscribe(collection: string) {
    const query = new Parse.Query(collection);
    return query.subscribe();
  }

  userSubscription(userId: string) {
    const query = new Parse.Query('User');
    query.equalTo('objectId', userId);
    return query.subscribe();
  }

  async userLogout() {
    return Parse.User.logOut();
  }

  async currentUser() {
    return Parse.User.currentAsync();
  }

  run(cloudFunction: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.networkService.isConnected()) {
        const startAt = Date.now();
        Parse.Cloud.run(cloudFunction, data)
          .then((response) => {
            const duration = Date.now() - startAt;
            this.logEvent(cloudFunction, duration);
            return response;
          })
          .then((response) => {
            resolve(JSON.parse(JSON.stringify(response)));
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        // this.network.showAlert();
        reject(ConnectionError.CONNECTION_ERROR);
      }
    });
  }

  private async logEvent(functionName, duration) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.PARSE_FUNCTION, {
      duration,
      cloud_function: functionName,
      connectionType: await this.networkService.connectionType(),
      platforms: this.networkService.getPlatforms()
    });
  }
}
