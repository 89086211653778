import { Injectable } from '@angular/core';
import { ChangePasswordPage } from '@app/change-password/change-password/change-password.page';
import { ReferralDialogPage } from '@app/core/referral-dialog/referral-dialog.page';
import { TransferringContextService } from '@app/core/user/transferring-context.service';
import { CycleListPage } from '@app/pages/cycle/cycle-list/cycle-list.page';
import { MetasPage } from '@app/pages/metas/metas.page';
import { ModalRatePage } from '@app/pages/modal-rate/modal-rate.page';
import { NotificationSettingsPage } from '@app/pages/notification-settings/notification-settings.page';
import { StatisticTabPage } from '@app/statistics/statistic-tab/statistic-tab.page';
import { TopicListPage } from '@app/topics/topic-list/topic-list.page';
import { AlertController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { ChartType } from '@models/ChartModel';
import { AmplitudeService } from '@services/amplitude/amplitude.service';
import { EstudoService } from '@services/api/estudo/estudo.service';
import { InAppBrowserService } from '@services/api/in-app-browser/in-app-browser.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { DeviceService } from '@services/device/device.service';
import { LoadingService } from '@services/loading/loading.service';
import { StorageService } from '@services/storage/storage.service';
import { NetworkService } from '@services/utils/network.service';
import { environment } from 'src/environments/environment';
import { EXTERNAL_LINKS } from 'src/assets/data/externalLinks';
import { DeleteAccountModalComponent } from '@app/pages/delete-account-modal/delete-account-modal.component';
import { ToastService } from '@services/toast/toast.service';
import { PremiumService } from '@services/premium/premium.service';
import { ChooseStudyplanTypePage } from '@app/study/choose-studyplan-type/choose-studyplan-type.page';
import { EventService } from '@services/event-service/event.service';
import { MyExamsListPage } from '@app/my-exams/my-exams-list/my-exams-list.page';
import { HelpBotPage } from '@app/shared/help-bot/help-bot.page';
import { ExamService } from '@services/api/exam/exam.service';
import { MyExamCreateHelperPage } from '@app/my-exams/my-exam-create-helper/my-exam-create-helper.page';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  public showSubmenu: boolean = false;
  public showSubmenu2: boolean = false;
  constructor(
    private amplitudeService: AmplitudeService,
    private modalController: ModalController,
    private alertController: AlertController,
    private networkService: NetworkService,
    private deviceService: DeviceService,
    private inAppBrowserService: InAppBrowserService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private transferringService: TransferringContextService,
    private estudoService: EstudoService,
    private toastService: ToastService,
    private userStoreService: UserStoreService,
    private menuController: MenuController,
    private premiumService: PremiumService,
    private eventService: EventService,
    private examService: ExamService
  ) {}

  async onMyExam() {
    const hasExams = await this.examService.hasExams();

    if (hasExams) {
      const modal = await this.modalController.create({
        component: MyExamsListPage,
        cssClass: 'sd modal-transparent'
      });
      await modal.present();
    } else {

      const reward = await this.premiumService.waitResolvePremiumModal();

      if(reward) {
        const modal = await this.modalController.create({
          component: MyExamCreateHelperPage,
          cssClass: 'sd modal-transparent',
          componentProps: {
            startFromFirstStep: true
          }
        });
  
        await modal.present();
      }
    }
  }

  async openExternalLink(id: string) {
    const { amplitudeEvent, link } = EXTERNAL_LINKS[id];
    this.amplitudeService.logEvent(amplitudeEvent);
    this.inAppBrowserService.openLinkOnBrowser(link);
  }

  async onGoalsClick() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_GOALS);

    const modal = await this.modalController.create({
      component: MetasPage,
      cssClass: 'sd modal-transparent'
    });
    return await modal.present();
  }

  async onCycleClick() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_MY_CYCLES);

    const modal = await this.modalController.create({
      component: CycleListPage,
      animated: false,
      cssClass: 'sd modal-transparent'
    });
    return await modal.present();
  }

  async onNotificationsClick() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_NOTIFICATION);
    const modal = await this.modalController.create({
      cssClass: 'sd modal-transparent',
      component: NotificationSettingsPage,
      animated: false
    });

    modal.present();
  }

  async onSubjectsAndTopicsClick() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_MY_SUBJECTS_TOPICS);
    const modal = await this.modalController.create({
      cssClass: 'sd modal-transparent',
      component: TopicListPage,
      animated: false
    });

    modal.present();
  }

  async onPerformanceClick() {
    const canOpen = await this.premiumService.waitResolvePaywall();

    if (canOpen) {
      const modal = await this.modalController.create({
        component: StatisticTabPage,
        cssClass: 'fullscreen modal-transparent',
        componentProps: {
          selectedType: 'semana',
          selectedChart: ChartType.EFICIENCIA
        }
      });
      await modal.present();
    }
  }

  onImprovementsSurvey() {
    this.networkService.executeIfOnline(async () => {
      try {
        const p = await this.estudoService.getPesquisas();

        if (p.length > 0) {
          this.inAppBrowserService.openLinkOnBrowser(p[0].link);
        } else {
          this.showToastMessage('Não temos pesquisas ativas no momento..');
        }
      } catch (e) {
        this.showToastMessage('Ops. Erro ao buscar pesquisas.');
      }
    });
  }

  onReportBug() {
    this.networkService.executeIfOnline(async () => {
      const userEmail: string = this.userStoreService.user?.email ?? '';

      const subject = `Bug reportado pelo app - Versão ${environment.appVersion} ${userEmail}`;
      const message = `Por favor coloque abaixo a descrição do seu problema, e se possível anexe um print do erro neste email.\n\n\n\n\n\n\n
      ___
      Por favor desconsidere as informações abaixo, são apenas controles internos direcionados ao nosso time.
      
      ${await this.deviceService.getFormattedDeviceInfo()}
      `;
      this.inAppBrowserService.openLinkOnBrowser(
        `mailto:suporte@estudaqui.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
      );
    });
  }

  async onChangePassword() {
    await this.menuController.close();

    const modal = await this.modalController.create({
      component: ChangePasswordPage,
      cssClass: 'sd modal-transparent'
    });
    modal.present();
  }

  async openModalRate() {
    const modal = await this.modalController.create({
      component: ModalRatePage,
      cssClass: 'sd modal-transparent'
    });
    await modal.present();
  }

  async openDeleteAccount() {
    await this.menuController.close();

    const modal = await this.modalController.create({
      component: DeleteAccountModalComponent,
      cssClass: 'sd modal-transparent'
    });
    modal.present();
  }

  async restoreData() {
    await this.loadingService.show('Aguarde. Restaurando.');
    const contextResult = await this.transferringService.restoreContextFromAssets();
    Object.keys(contextResult).forEach(async (key) => {
      await this.storageService.set(key, contextResult[key]);
    });
    await this.loadingService.dismiss();
  }

  async transferData() {
    await this.loadingService.show('Aguarde. Gerando arquivos para transferência.');
    try {
      await this.transferringService.shareFiles();
      await this.loadingService.dismiss();
    } catch (err) {
      await this.loadingService.dismiss();

      const alert = await this.alertController.create({
        header: 'Erro',
        message: 'Erro ao transferir contexto | ' + JSON.stringify(err, Object.getOwnPropertyNames(err))
      });

      await alert.present();
    }
  }

  async openChoosePlanTypeModal() {
    const reward = await this.premiumService.waitResolvePremiumModal();

    if (reward) {
      this.eventService.publish('OPEN_SELECT_PLAN');
    }
  }

  async onHelpSync() {
    const modalReset = await this.modalController.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        avatar: 'assets/imgs/metas/help-bot.png',
        message: `O <símbolo de sincronização> pendente indica que há dados no seu dispositivo que ainda não foram salvos na nuvem (em nosso servidor).
        Pelo menos <1 vez ao dia> clique em sincronizar para manter seus dados seguros.
        PS: se você usa o app em vários dispositivos, <todos precisam ser sincronizados individualmente>.`,
        title: 'Sincronização',
        buttons: [
          {
            name: 'ENTENDI',
            className: 'entendi',
            shouldClose: true,
            handler: () => {}
          }
        ]
      }
    });

    modalReset.present();
  }

  onShowSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  hiddenSubmenu() {
    this.showSubmenu = false;
  }


  onShowSubmenu2() {
    this.showSubmenu2 = !this.showSubmenu2;
  }

  hiddenSubmenu2() {
    this.showSubmenu2 = false;
  }

  openContact() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_CONTACT);
  }

  showToastMessage(title: string) {
    this.toastService.create(title);
  }
}
