import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectPeriodoComponent } from "@app/components/select-periodo/select-periodo.component";
import { OfflineTutorialsPage } from "@app/offline-tutorials/offline-tutorials.page";
import { BasePageController } from "@app/pages/BasePage";
import { NavParams } from "@ionic/angular";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { ChartType } from "@models/ChartModel";
import { IPeriod } from "@models/IPeriod";
import { Statistic, StatisticPageType } from "@models/Statistic";
import { StatisticCachingService } from "@services/api/caching/statistic-caching/statistic-caching.service";
import { BackButtonService } from "@services/back-button/back-button.service";
import { StatisticService } from "@services/statistic/statistic.service";
import { StatisticTabMonthPage } from "./statistic-tab-month/statistic-tab-month.page";
import { StatisticTabWeekPage } from "./statistic-tab-week/statistic-tab-week.page";
import { StatisticTabYearPage } from "./statistic-tab-year/statistic-tab-year.page";
import { PremiumService } from "@services/premium/premium.service";

@Component({
  selector: 'app-statistic-tab',
  templateUrl: './statistic-tab.page.html',
  styleUrls: ['./statistic-tab.page.scss']
})
export class StatisticTabPage extends BasePageController {
  @ViewChild(SelectPeriodoComponent)
  selectPeriodoComponent: SelectPeriodoComponent;

  @ViewChild('week') semanaPage: StatisticTabWeekPage;
  @ViewChild('month') mesPage: StatisticTabMonthPage;
  @ViewChild('year') anoPage: StatisticTabYearPage;
  currentTab: ChartType = ChartType.TEMPO;
  statistics: Array<Statistic> = [];
  period: IPeriod;
  tipo: StatisticPageType = 'dia';

  loadedYears = {};
  loadedMonths = {};
  loadedWeeks = {};

  metaAnalytics = {
    title: 'Aba Estatísticas'
  };

  constructor(
    private statisticCachingService: StatisticCachingService,
    private statisticService: StatisticService,
    private navParams: NavParams,
    private backButtonService: BackButtonService,
    private premiumService: PremiumService
  ) {
    super();
  }

  ionViewWillEnter() {   
    this.tipo = this.navParams.data.selectedType || 'dia';
    this.currentTab = this.navParams.data.selectedChart || ChartType.TEMPO;

    const sub = this.backButtonService.subscribeWithPriority(101, () => {
      if (this.tipo != 'dia' && this.statisticService.hasActiveDrilldown()) {
        if (this.statisticService.backDrilldown()) {
          return;
        }
      }

      this.modalCtrl.dismiss();
    });

    this.pushSubscription(sub);
  }

  ionViewDidEnter() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.SC_STATISTICS);
    this.logEventPeriodType(this.tipo);
  }

  ionViewDidLeave() {
    this.unsubscribeAllSubscriptions();
  }

  async openTutorialTab() {
    const tutorialDialog = await this.modalCtrl.create({
      component: OfflineTutorialsPage,
      componentProps: {
        title: 'Tela Estatísticas',
        youtubeURL: 'https://youtu.be/ihOTCQXnOM0',
        message: `A tela de <Estatísticas> é importante para o <comparativo> e <melhoria contínua> de seu estudo, nela você verá:
          • em quais dias <suas metas de estudo> foram <batidas;>
          • gráficos de <tempo exercícios, páginas e eficiência;>
          • performance de estudo por <dia da semana,> por <disciplina> e por <assunto;>
          • <outros detalhes> mostrados <no tutorial>`
      },
      cssClass: 'sd modal-transparent'
    });

    tutorialDialog.present();
  }

  async onMoreGraphic() {
    const canOpen = await this.premiumService.waitResolvePaywall();

    if (canOpen) {
      this.tipo = 'semana';
      this.currentTab = ChartType.TEMPO;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async onVoltar(changePeriod: IPeriod) {
    await this.getEstatistica(changePeriod);
  }

  async onAvancar(changePeriod: IPeriod) {
    await this.getEstatistica(changePeriod);
  }

  async getEstatistica(changePeriod: IPeriod) {
    this.period = changePeriod;

    const obj = {
      periodo: {
        start: changePeriod.start.format('YYYY-MM-DD'),
        end: changePeriod.end.format('YYYY-MM-DD'),
        email: this.userStoreService.user.username
      }
    };

    if (this.tipo === 'semana') {
      const key: string = changePeriod.start.toISOString();
      if (Object.prototype.hasOwnProperty.call(this.loadedYears, key)) {
        this.statistics = this.loadedWeeks[key];
        this.semanaPage.plotCharts();
        //await this.loading.dismiss();
        return;
      }
    } else if (this.tipo === 'mes') {
      const key = changePeriod.start.format('MM_YYYY');
      if (Object.prototype.hasOwnProperty.call(this.loadedMonths, key)) {
        this.statistics = this.loadedMonths[key];
        setTimeout(() => {
          this.mesPage.plotCharts();
        }, 200);
        //await this.loading.dismiss();
        return;
      }
    } else if (this.tipo === 'ano') {
      const key = changePeriod.start.year();
      if (Object.prototype.hasOwnProperty.call(this.loadedYears, key)) {
        this.statistics = this.loadedYears[key];
        setTimeout(() => {
          this.anoPage.plotCharts();
        }, 200);
        // await this.loading.dismiss();
        return;
      }
    }

    try {
      const read = await this.statisticCachingService.read();
      const statistics = !read
        ? []
        : read.filter((statisticItem) => {
            const createdAt = new Date(statisticItem.data);
            return (
              createdAt.getTime() >= new Date(obj.periodo.start).getTime() &&
              createdAt.getTime() <= new Date(obj.periodo.end).getTime()
            );
          });

      this.statistics = statistics;

      if (this.tipo === 'semana') {
        this.loadedWeeks[changePeriod.start.toISOString()] = statistics;
        this.semanaPage.plotCharts();
      } else if (this.tipo === 'mes') {
        this.loadedMonths[changePeriod.start.format('MM_YYYY')] = statistics;
        this.mesPage.plotCharts();
      } else if (this.tipo === 'ano') {
        this.loadedYears[changePeriod.start.year()] = statistics;
        this.anoPage.plotCharts();
      }
    } catch (err) {
      this.statistics = [];
      this.networkService.showOfflineAlert();
      console.error(err);
    }
  }

  private reloadStatistics() {
    this.statistics = [];
    let period: IPeriod;

    if (this.tipo === 'semana') {
      period = this.selectPeriodoComponent.getWeekPeriod();
    } else if (this.tipo === 'mes') {
      period = this.selectPeriodoComponent.getMonthPeriod();
    } else if (this.tipo === 'ano') {
      period = this.selectPeriodoComponent.getYearPeriod();
    } else {
      return;
    }
    this.getEstatistica(period);
  }

  async onSegmentChange(event) {
    const tipo = event.detail.value;
    if(tipo === '') {
      return;
    }
    this.logEventPeriodType(tipo);

    if (tipo !== 'dia') {
      const canOpen = await this.premiumService.waitResolvePaywall();
      if (canOpen) {
        this.tipo = tipo as StatisticPageType;
        this.statisticService.reset();
        this.reloadStatistics();
      } else {
        this.tipo = 'dia';
      }
    }
  }

  onChangeTab(chartType: ChartType) {
    this.currentTab = chartType;
  }

  get isTimeTabSelected(): boolean {
    return this.currentTab === ChartType.TEMPO;
  }

  get isExercisesTabSelected(): boolean {
    return this.currentTab === ChartType.EXERCICIOS;
  }

  get isPagesTabSelected(): boolean {
    return this.currentTab === ChartType.PAGINAS;
  }

  get isEfficiencyTabSelected(): boolean {
    return this.currentTab === ChartType.EFICIENCIA;
  }

  logEventChartType() {
    let eventType: AmplitudeEventEnum = AmplitudeEventEnum.BT_STATISTICS_TIME;

    if (this.isExercisesTabSelected) {
      eventType = AmplitudeEventEnum.BT_STATISTICS_EXERCISES;
    } else if (this.isPagesTabSelected) {
      eventType = AmplitudeEventEnum.BT_STATISTICS_PAGES;
    } else if (this.isEfficiencyTabSelected) {
      eventType = AmplitudeEventEnum.BT_STATISTICS_EFFICIENCY;
    }

    this.amplitudeService.logEvent(eventType);
  }

  logEventPeriodType(period: string) {
    let eventType: AmplitudeEventEnum = AmplitudeEventEnum.BT_STATISTICS_DAY;

    if (period === 'semana') {
      eventType = AmplitudeEventEnum.BT_STATISTICS_WEEK;
    } else if (period === 'mes') {
      eventType = AmplitudeEventEnum.BT_STATISTICS_MONTH;
    } else if (period === 'ano') {
      eventType = AmplitudeEventEnum.BT_STATISTICS_YEAR;
    }

    this.amplitudeService.logEvent(eventType);
  }

  get chartType(): typeof ChartType {
    return ChartType;
  }
}
