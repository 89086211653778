import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RedirectGuard } from './guards/redirect/redirect.guard';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { StorageService } from '@app/@services/storage/storage.service';
import { AppInjectorService } from '@app/@services/app-injector/app-injector.service';
import { MetasPageModule } from './pages/metas/metas.module';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { DetalheGraficoModalPageModule } from './pages/detalhe-grafico-modal/detalhe-grafico-modal.module';
import { CreateReviewPageModule } from './reviews/create-review/create-review.module';
import { HammerConfig } from '@services/hammer-config.service';
import { WeeklyScheduleModule } from './weekly-schedule/weekly-schedule.module';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ChronometerPageModule } from './study/chronometer/chronometer.module';
import { SharedModule } from './shared/shared.module';
import { AlarmModalPageModule } from './study/alarm-modal/alarm-modal.module';
import { ChooseRegistertypeModalPageModule } from './study/shared/choose-registertype-modal/choose-registertype-modal.module';
import { ChooseStudyTypeModalPageModule } from './study/study-register-modal/choose-study-type-modal/choose-study-type-modal.module';
import { CheckoutSelectPlanPageModule } from './subscriptions/checkout-select-plan/checkout-select-plan.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { ChooseStudyplanTypePageModule } from './study/choose-studyplan-type/choose-studyplan-type.module';
import { SentryService } from './core/sentry.service';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { File } from '@ionic-native/file/ngx';
import { HttpClientModule } from '@angular/common/http';
import { OfflineTutorialsPageModule } from './offline-tutorials/offline-tutorials.module';
import { CheckoutSelectAndroidPlanPageModule } from './subscriptions/checkout-select-android-plan/checkout-select-android-plan.module';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { registerLocaleData } from '@angular/common';
import { MenuFooterComponent } from './menu/menu-footer/menu-footer.component';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { DeleteAccountModalModule } from './pages/delete-account-modal/delete-account-modal.module';
import { DeleteDataModalPageModule } from './pages/delete-data-modal/delete-data-modal.module';
import { ChronometerDisciplinesListPageModule } from './study/chronometer-disciplines-list/chronometer-disciplines-list.module';

import ptBr from '@angular/common/locales/pt';
import { StatisticHistoryTabPageModule } from './statistics/statistic-history-tab/statistic-history-tab.module';
import { WelcomeTrialPageModule } from './pages/welcome-trial/welcome-trial.module';
import { SessionExpiredModalModule } from './pages/session-expired-modal/session-expired-modal.module';
import { DisciplineCycleAddDialogPageModule } from './disciplines/discipline-cycle-add-dialog/discipline-cycle-add-dialog.module';
import { initAppFactory } from './InitializeAppFactory';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { MigrationService } from '@services/migration-service/migration-service.service';
import { PlansComparePageModule } from './pages/plans-compare/plans-compare.module';
import { AdMob } from '@admob-plus/ionic/ngx';
import { MyExamsListPageModule } from './my-exams/my-exams-list/my-exams-list.module';
import { MyExamCreatePageModule } from './my-exams/my-exam-create/my-exam-create.module';
import { MyExamEditSubjectPageModule } from './my-exams/my-exam-edit-subject/my-exam-edit-subject.module';
import { MyExamCreateHelperPageModule } from './my-exams/my-exam-create-helper/my-exam-create-helper.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider, FacebookLoginProvider
} from 'angularx-social-login';
import { WelcomeWebPageModule } from './pages/welcome-web/welcome-web.module';

registerLocaleData(ptBr);
export function highchartsFactory() {
  const Highcharts = require('highcharts/highstock');
  const Highmaps = require('highcharts/highmaps');

  const more = require('highcharts/highcharts-more');
  const exporting = require('highcharts/modules/exporting');
  const drilldown = require('highcharts/modules/drilldown');
  const heatmap = require('highcharts/modules/heatmap');
  const exdata = require('highcharts/modules/export-data');
  const stock = require('highcharts/modules/stock');
  more(Highcharts);
  exporting(Highmaps);
  drilldown(Highmaps);
  heatmap(Highmaps);
  exdata(Highmaps);
  stock(Highcharts);

  Highmaps.setOptions({
    credits: false
  });
  return Highmaps;
}

@NgModule({
  declarations: [AppComponent, MenuFooterComponent],
  entryComponents: [],
  imports: [
    ChronometerDisciplinesListPageModule,
    SimpleMaskModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    AlarmModalPageModule,
    CheckoutSelectAndroidPlanPageModule,
    CheckoutSelectPlanPageModule,
    ChooseRegistertypeModalPageModule,
    ChooseStudyTypeModalPageModule,
    OfflineTutorialsPageModule,
    ChronometerPageModule,
    CreateReviewPageModule,
    DeleteAccountModalModule,
    DeleteDataModalPageModule,
    WelcomeTrialPageModule,
    SessionExpiredModalModule,
    PlansComparePageModule,
    DetalheGraficoModalPageModule,
    DisciplineCycleAddDialogPageModule,
    MetasPageModule,
    BrowserModule,
    SharedModule,
    WeeklyScheduleModule,
    ChooseStudyplanTypePageModule,
    StatisticHistoryTabPageModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    MyExamsListPageModule,
    MyExamCreatePageModule,
    MyExamCreateHelperPageModule,
    MyExamEditSubjectPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    IonicModule.forRoot({ hardwareBackButton: true, mode: 'md' })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt_BR' },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    AdMob,
    BackgroundMode,
    NativeAudio,
    Vibration,
    LocalNotifications,
    Facebook,
    GooglePlus,
    StorageService,
    File,
    InAppBrowser,
    InAppPurchase,
    SplashScreen,
    SocialSharing,
    SentryService,
    Screenshot,
    Network,
    Platform,
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory
    },
    RedirectGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [StorageService, MigrationService, UserStoreService],
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjectorService.setInjector(injector);
  }
}
