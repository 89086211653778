import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import TimeUtils from "@app/util/TimeUtil";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { EffectivenessUnit, EffectivenessDict } from "@models/ChartModel";
import { IPeriod } from "@models/IPeriod";
import { Statistic, StatisticPageType } from "@models/Statistic";
import { AmplitudeService } from "@app/@services/amplitude/amplitude.service";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { StatisticService } from "@app/@services/statistic/statistic.service";
import moment from "moment";
import { getChart1, getChart2, getChart3 } from "./chart.config";
import DateUtils from "@app/util/DateUtils";
import ColorUtils from "@app/util/ColorUtils";
import * as _ from "underscore";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
More(Highcharts);
import Drilldown from "highcharts/modules/drilldown";
Drilldown(Highcharts);
import Exporting from "highcharts/modules/exporting";
import ChartUtils from "@app/util/ChartUtils";
Exporting(Highcharts);

const SlideName = {
  0: "Eficiencia (horas liquida/total)",
  1: "Eficiencia por disciplina/assunto",
  2: "Eficiencia por dia da semana",
};

@Component({
  selector: "app-efficiency-charts",
  templateUrl: "./efficiency-charts.component.html",
  styleUrls: ["./efficiency-charts.component.scss"],
})
export class EfficiencyChartsComponent implements AfterViewInit {
  @Input() statistics: Array<Statistic>;
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;

  readonly CHART_HEIGHT = 400;
  
  private statisticsGroupedByDiscipline: Array<Statistic> = [];
  private statisticsByCronometer: Array<Statistic> = [];
  timeEffectiveRatio = "0";
  timeStopped = "00:00";
  chart: Highcharts.Chart;
  

  constructor(
    private cdr: ChangeDetectorRef,
    private topicsProvider: TopicsService,
    private statisticProvider: StatisticService,
    private amplitudeProvider: AmplitudeService
  ) {}

  ngAfterViewInit() {
    this.plotAll();
    this.cdr.detectChanges();
  }

  get hasStatistics(): boolean {
    this.statisticsByCronometer = this.statistics.filter(
      (item) => item.tipo === "cronometro"
    );
    return this.statisticsByCronometer.length > 0;
  }

  plotAll() {
    if (this.hasStatistics) {
      const totalTimeStopped = this.statisticsByCronometer
        .map((statistic) =>
          statistic.tempo_parado ? statistic.tempo_parado : 0
        )
        .reduce((acc, curr) => acc + curr);
      this.statisticsGroupedByDiscipline = _.groupBy(
        this.statisticsByCronometer,
        "curso"
      );
      this.timeEffectiveRatio = this.getPercentageEffectiveHours(
        this.statisticsByCronometer
      ).toFixed(0);
      this.timeStopped = TimeUtils.formatHHMM(totalTimeStopped);
      this.plotChart1();
      this.plotChart2();
      this.plotChart3();
    } else {
      this.statisticsGroupedByDiscipline = [];
      this.timeEffectiveRatio = "0";
      this.timeStopped = "00:00";
    }
  }

  private plotChart1() {
    const start = this.period.start.clone();
    const end = this.period.end.clone();

    const disciplineTimeDict: {
      [key: string]: EffectivenessUnit;
    } = {};

    const data = [];

    if (this.tipo === "semana") {

      while (start.isBefore(end) || start.isSame(end)) {
        disciplineTimeDict[start.format("YYYY-MM-DD")] = {
          runningTime: 0,
          stoppedTime: 0,
        };

        start.add(1, "day");
      }

      for (const stat of this.statisticsByCronometer) {
        disciplineTimeDict[stat.data].runningTime += stat.tempo;
        disciplineTimeDict[stat.data].stoppedTime += stat.tempo_parado
          ? stat.tempo_parado
          : 0;
      }

      for (const key in disciplineTimeDict) {
        data.push({
          name: DateUtils.formatByType(key, this.tipo),
          y: this.getPercentage(
            disciplineTimeDict[key].runningTime,
            disciplineTimeDict[key].stoppedTime
          ),
        });
      }
    } else if (this.tipo === "mes") {
      const firstDayOfWeek = start.clone().startOf("week").add(1, "day");
      const lastDayOfWeek = start.clone().endOf("week").add(1, "day");

      const weekDictBase: {
        [key: string]: {
          start: string;
          end: string;
          runningTime: number;
          stoppedTime: number;
        };
      } = {};

      while (firstDayOfWeek.isBefore(end) || firstDayOfWeek.isSame(end)) {
        const weekRange =
          firstDayOfWeek.format("YYYY-MM-DD") +
          "|" +
          lastDayOfWeek.format("YYYY-MM-DD");

        weekDictBase[weekRange] = {
          start: firstDayOfWeek.format(),
          end: lastDayOfWeek.format(),
          runningTime: 0,
          stoppedTime: 0,
        };

        firstDayOfWeek.add(1, "week");
        lastDayOfWeek.add(1, "week");
      }

      const weekDict = JSON.parse(JSON.stringify(weekDictBase));

      for (const stat of this.statisticsByCronometer) {
        const date = moment(stat.data, "YYYY-MM-DD");

        for (const range in weekDict) {
          if (
            date.isBetween(
              moment(weekDict[range].start),
              moment(weekDict[range].end),
              "days",
              "[]"
            )
          ) {
            weekDict[range].runningTime += stat.tempo;
            weekDict[range].stoppedTime += stat.tempo_parado
              ? stat.tempo_parado
              : 0;
          }
        }
      }

      for (const key in weekDict) {
        data.push({
          name: DateUtils.formatByType(key, this.tipo),
          y: this.getPercentage(
            weekDict[key].runningTime,
            weekDict[key].stoppedTime
          ),
        });
      }
    } else {
      const monthDict: {
        [key: string]: {
          runningTime: number;
          stoppedTime: number;
        };
      } = {
        "0": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "1": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "2": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "3": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "4": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "5": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "6": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "7": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "8": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "9": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "10": {
          runningTime: 0,
          stoppedTime: 0,
        },
        "11": {
          runningTime: 0,
          stoppedTime: 0,
        },
      };

      for (const stat of this.statisticsByCronometer) {
        const date = moment(stat.data, "YYYY-MM-DD");
        const month = date.month();
        monthDict[month].runningTime += stat.tempo;
        monthDict[month].stoppedTime += stat.tempo_parado
          ? stat.tempo_parado
          : 0;
      }

      for (const key in monthDict) {
        data.push({
          name: DateUtils.formatByType(key, this.tipo),
          y: this.getPercentage(
            monthDict[key].runningTime,
            monthDict[key].stoppedTime
          ),
        });
      }
    }
    const height = data.length * 75;
    const chart = getChart1(data, height);
    new Highcharts.Chart(chart as any).reflow();
  }

  private plotChart2() {
    const disciplinesData: Array<any> = [];

    _.each(
      this.statisticsGroupedByDiscipline,
      (group: Array<Statistic>, discipline: string) => {
        const percentage = this.getPercentageEffectiveHours(group);
        disciplinesData.push({
          name: discipline,
          y: percentage,
          color: ColorUtils.getColorFromStatistic(group[0]),
          extraData:
            group.length === 0
              ? null
              : { discipline: group[0].disciplina_usuario, group: group },
        });
      }
    );

    const chart = getChart2(
      disciplinesData.map((item) => {
        item.drilldown = true;
        return item;
      })
    );

    const chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 35, this.CHART_HEIGHT);


    const exerciseChart2 = new Highcharts.Chart({
      lang: {
        drillUpText: "◁ Voltar",
      },
      chart: {
        type: "column",
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            this.logEvent(SlideName[1], "drillup");
            this.statisticProvider.reset();

            exerciseChart2.setSize(null, chartHeight);
            exerciseChart2.setSubtitle({
              text: `
                <div class="img-detail-click">
                  <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
                  <span>Clique na disciplina para detalhar Assuntos</span>
                </div>
              `,
              useHTML: true,
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[1], "drilldown");
            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: "Exerc. por disciplina",
                colorByPoint: true,
                data: [],
              };
              exerciseChart2.showLoading("Aguarde...");
              const topics = await this.topicsProvider.findTopicsByDiscipline(
                extraData.discipline
              );
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const grpuppedStatistics = extraData.group as Statistic[];
              const statisticsWithNoTopics: Statistic[] = [];
              const filteredStatistics = grpuppedStatistics.filter(
                (statistic) => {
                  if (!statistic.topic || !statistic.topic.localObjectId)
                    statisticsWithNoTopics.push(statistic);
                  return (
                    statistic.topic &&
                    statistic.topic.localObjectId &&
                    topicLocalIds.includes(statistic.topic.localObjectId)
                  );
                }
              );
              series.data = topics
                .map((topic) => {
                  const topicStatistics = filteredStatistics.filter(
                    (statistic) =>
                      statistic.topic.localObjectId === topic.localObjectId
                  );

                  if (topicStatistics.length === 0) return null;

                  const percentage =
                    this.getPercentageEffectiveHours(topicStatistics);

                  return {
                    name: topic.description,
                    y: percentage,
                    color: topic.color.codigo,
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0)
                series.data.push({
                  color: "#dddddd",
                  name: "Não definido *",
                  y: this.getPercentageEffectiveHours(statisticsWithNoTopics),
                });
              
              const chartHeightDrillDown = ChartUtils.calculateHeight(series.data.length, 35, this.CHART_HEIGHT);
              
              exerciseChart2.setSize(null, chartHeightDrillDown);
              exerciseChart2.setSubtitle({
                text: extraData.discipline.descricao,
              });
              exerciseChart2.hideLoading();
              exerciseChart2.addSeriesAsDrilldown(e.point, series);
              this.chart = exerciseChart2;
              this.statisticProvider.openDrilldown(this);
            }
          },
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: chart.title,
      tooltip: chart.tooltip,
      subtitle: {
        text: `
          <div class="img-detail-click">
            <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
            <span>Clique na disciplina para detalhar Assuntos</span>
          </div>
        `,
        useHTML: true,
      },
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false,
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: "normal",
        },
        activeDataLabelStyle: {
          fontWeight: "normal",
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15,
          },
          theme: {
            fill: "#007aff",
            "stroke-width": 1,
            stroke: "silver",
            style: {
              color: "white",
              fontWeight: "bold",
            },
          },
        },
        series: [],
      },
    });

    exerciseChart2.reflow();
  }

  private plotChart3() {
    const effectivenessDict: EffectivenessDict = {
      "0": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "1": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "2": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "3": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "4": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "5": {
        runningTime: 0,
        stoppedTime: 0,
      },
      "6": {
        runningTime: 0,
        stoppedTime: 0,
      },
    };

    for (const statistic of this.statisticsByCronometer) {
      let element: EffectivenessUnit =
        effectivenessDict[moment(statistic.data, "YYYY-MM-DD").day()];

      if (element === undefined) {
        element = {
          runningTime: statistic.tempo,
          stoppedTime: statistic.tempo_parado ? statistic.tempo_parado : 0,
        };
      } else {
        element.runningTime += statistic.tempo;
        element.stoppedTime += statistic.tempo_parado
          ? statistic.tempo_parado
          : 0;
      }
    }

    const categories = [];
    const percentages = [];

    for (const key in effectivenessDict) {
      categories.push(DateUtils.getDayOfWeek(Number(key)));
      percentages.push(
        this.getPercentage(
          effectivenessDict[key].runningTime,
          effectivenessDict[key].stoppedTime
        )
      );
    }

    const chart = getChart3(categories, percentages);
    const hChart = new Highcharts.Chart(chart as any);
    hChart.reflow();
  }

  private getPercentageEffectiveHours(group: Array<Statistic>): number {
    const runningTime = group
      .map((item) => item.tempo)
      .reduce((acc, curr) => acc + curr, 0);
    const stoppedTime = group
      .map((item) => (item.tempo_parado ? item.tempo_parado : 0))
      .reduce((acc, curr) => acc + curr);
    return this.getPercentage(runningTime, stoppedTime);
  }

  private getPercentage(runningTime: number, stoppedTime: number): number {
    const totalTime = runningTime + stoppedTime;
    if (!totalTime) {
      return 0;
    }

    const percentage = (runningTime / totalTime) * 100;
    return percentage;
  }

  async slideChanged(event) {
    const { srcElement } = event;
    const {activeIndex} = srcElement.swiper;
    const realIndex = activeIndex;
    const slideName = SlideName[realIndex];
    this.logEvent(slideName);
  }

  logEvent(slideName: string, action?: string) {
    this.amplitudeProvider.logEvent(AmplitudeEventEnum.SC_STATISTICS_SLIDE, {
      chart: "efficiency-chart",
      period: this.tipo,
      slide_name: slideName,
      action,
    });
  }
}
