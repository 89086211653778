import { Component, Input } from '@angular/core';
import { Haptics } from '@capacitor/haptics';
import { AdMob } from '@admob-plus/ionic/ngx';

import { BasePageController } from '@app/pages/BasePage';
import { CurrentSubscriptionPage } from '@app/subscriptions/current-subscription/current-subscription.page';
import { CheckoutSelectAndroidPlanPage } from '@app/subscriptions/checkout-select-android-plan/checkout-select-android-plan.page';
import { LoadingService } from '@services/loading/loading.service';
import { Events } from 'plugins/admob-plus-cordova/esm';
import { User } from '@models/User';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { AdsService } from '@services/ads/ads.service';
import { PlanIosListPage } from '@app/subscriptions/plan-ios-list/plan-ios-list.page';
import StringUtils from '@app/util/StringUtils';
import { timer } from 'rxjs/internal/observable/timer';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { tap } from 'rxjs/internal/operators/tap';
const FOOTER_ACTION_FREEMIUM_ADS_KEY = 'FOOTER_ACTION_FREEMIUM_ADS';

@Component({
  selector: 'app-plans-compare',
  templateUrl: './plans-compare.page.html',
  styleUrls: ['./plans-compare.page.scss']
})
export class PlansComparePage extends BasePageController {
  @Input()
  origin: 'EXPIRED' | 'BLOCK_OFFLINE' | 'BLOCK_PREMIUM' | 'PAY_WALL' | 'BLOCK_DESKTOP_FREEMIUM';
  @Input()
  isOffline: boolean = false;
  @Input()
  showCloseButton: boolean = true;

  isFreemium: boolean = true;
  isTrial: boolean;
  isPaywall: boolean = false;
  canAcceptTrial: boolean;
  showFreemiumAdsText: boolean;

  showOffline: boolean = false;
  showExpiraitonAlert: boolean = false;
  showDesktopAlert: boolean = false;
  user?: User;

  constructor(
    private admob: AdMob,
    private adsService: AdsService,
    private loadingService: LoadingService
  ) {
    super();
  }

  async ngOnInit() {
    this.showFreemiumAdsText = (await this.storageService.get(FOOTER_ACTION_FREEMIUM_ADS_KEY)) || false;
    if(this.origin !== 'BLOCK_DESKTOP_FREEMIUM') {
      this.isFreemium = this.userStoreService.userIsFreemium();
      this.isTrial = this.userStoreService.userIsTrial();
      this.canAcceptTrial = this.userStoreService.canAcceptTrial();
      this.user = this.userStoreService.user;
      this.amplitudeService.logEvent(AmplitudeEventEnum.AD_PLANS_COMPARE, {
        isFreemium: this.isFreemium,
        isOffline: this.isOffline,
        isTrial: this.isTrial
      });
    }
  
    this.backButtonActions();
    this.resolveOrigin();

  }

  get isUserLogged() {
    return !!this.userStoreService.user
  }

  async resolveOrigin() {
    if (this.origin === 'EXPIRED') {
      this.showFreemiumAdsText = false;
      this.isFreemium = false;
      this.showCloseButton = false;
      this.showExpiraitonAlert = true;
      await this.forceCheckUserIsExpired();
    } else if (this.origin === 'BLOCK_OFFLINE') {
      this.showFreemiumAdsText = false;
      this.isFreemium = false;
      this.showCloseButton = false;
      this.showOffline = true;
    } else if (this.origin === 'BLOCK_PREMIUM') {
      this.showFreemiumAdsText = true;
      this.isFreemium = true;
      this.showCloseButton = true;
      this.showOffline = false;
    } else if (this.origin === 'PAY_WALL') {
      this.showFreemiumAdsText = false;
      this.isFreemium = true;
      this.showCloseButton = true;
      this.showOffline = false;
      this.isPaywall = true;
    } else if (this.origin === 'BLOCK_DESKTOP_FREEMIUM') {
      this.showFreemiumAdsText = false;
      this.isFreemium = false;
      this.showOffline = true;
      this.showDesktopAlert = true;
    }
  }

  async forceCheckUserIsExpired() {
    const userIsExpired = await this.forceCheckIfUserIsExpired();

    if (!userIsExpired) {
      this.amplitudeService.logEvent('EV_plans_compare_forceCheckUserIsExpired', { userIsExpired });
      this.dismiss(true, false, false);
    }
  }

  ionViewDidEnter() {
    Haptics.vibrate({ duration: 100 });

    const sub = this.admob.on(Events.rewardedReward).subscribe(
      async (rewardedReward) => {
        const code = rewardedReward.Code;
        const reward = rewardedReward.reward;

        await this.loadingService.dismiss();

        if (code === 3) {
          await this.showAlert({
            header: '',
            subHeader: 'Limite de rewards em 24 horas atingido. <br>Tente mais tarde.'
          });
        }

        if (reward) {
          this.dismiss(true);
          this.amplitudeService.logEvent(AmplitudeEventEnum.AD_REWARDED_REWARD, { rewardedReward });
        }
      },
      async (err) => this.handleAdmobUnkownError(err));

    this.pushSubscription(sub);
  }

  ionViewWillLeave() {
    this.unsubscribeAllSubscriptions();
  }

  private backButtonActions() {
    const sub = this.platform.backButton.subscribeWithPriority(110, (_) => {
      if (this.showCloseButton) {
        this.dismiss();
        return;
      }
    });

    this.pushSubscription(sub);
  }

  async openPlans() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.AD_OPEN_PLANS);

    const modal = await this.modalCtrl.create({ component: CheckoutSelectAndroidPlanPage });
    await modal.present();
    await modal.onDidDismiss();
  }

  async restoreSignature() {
    const subscription = await this.modalCtrl.create({ component: CurrentSubscriptionPage });
    subscription.present();
  }

  async openAds() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.AD_OPEN_ADS);

    try {
      await this.loadingService.show('Carregando vídeo');
      const startAt = Date.now();

      const rewarded = await this.adsService.loadRewardedAd();

      const timeElapsed = Date.now() - startAt;
      await this.loadingService.dismiss();

      this.amplitudeService.logEvent(AmplitudeEventEnum.AD_REWARD_LOAD, { timeElapsed });

      await rewarded.show();
    } catch (e) {
      await this.loadingService.dismiss().catch((e) => {});

      if (StringUtils.isJsonString(e)) {
        const error = JSON.parse(e);
        const code = error.Code || 0;
        const message: string = error.Message || '';
        if (code === 3 && message.includes('Frequency cap reached')) {
          this.amplitudeService.logEvent(AmplitudeEventEnum.AD_REWARD_LIMIT_REACHED);
          await this.showAlert({
            header: '',
            message: 'Limiter de recompensas em 24 horas atingido. <br/> Tente novamente mais tarde!'
          });
          return;
        }
      }
      
      this.handleAdmobUnkownError(e);
    }
  }

  openVideo() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.AD_OPEN_VIDEO);
  }

  async openSubscribe() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_PLANS_COMPARE_OPEN_SUBSCRIPTIONS);

    const modal = await this.resolveOpenModalSubscribe();
    await modal.present();
    await modal.onDidDismiss();

    const user = await this.userService.isUserExpired(this.user.objectId);
    await this.userStoreService.saveUser(user);
    if (!this.userStoreService.userIsExpired() && !this.userStoreService.userIsFreemium()) {
      this.dismiss(true, false, false);
    }
  }

  async resolveOpenModalSubscribe(): Promise<HTMLIonModalElement> {
    const params = { userId: this.userStoreService.user.objectId };

    if (this.isAndroid) {
      return this.modalCtrl.create({
        component: CheckoutSelectAndroidPlanPage,
        componentProps: { ...params }
      });
    }
    return this.modalCtrl.create({
      component: PlanIosListPage,
      componentProps: { ...params }
    });
  }

  async acceptFreemium() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.AD_OPEN_ACCEPT_FREEMIUM);
    if (this.origin !== 'EXPIRED') {
      await this.openAds().catch((e) => {});
    }
    this.dismiss(true, true);
  }

  get showCloseButtond(): boolean {
    return this.isFreemium && !this.canAcceptTrial && this.showFreemiumAdsText;
  }

  private async handleAdmobUnkownError(err) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.AD_REWARDED_REWARD_ERROR, err);
    let timerCount = 10;
    const loading = await this.showLoading(`Liberando em ${timerCount} s`);
    await timer(1000, 1000)
      .pipe(
        takeWhile(() => timerCount > 0),
        tap(() => {
          timerCount--;
          loading.message = `Liberando em ${timerCount} s`;
        })
      )
      .toPromise();
    this.dismiss(true);
    loading.dismiss();
  }

  dismiss(rewared?: boolean, acceptFreemium?: boolean, acceptTrial?: boolean) {
   return this.modalCtrl.dismiss(
      { rewared: rewared ?? false, acceptFreemium: acceptFreemium ?? false, acceptTrial: acceptTrial ?? false },
      null,
      'PremiumModal'
    ); 
  }

  async dismissAndLogout() {
    await this.dismiss();
  }
}
