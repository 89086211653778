import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CustomDateTimeDirective } from "@app/directives/custom-date-time/custom-date-time";
import { ChooseDisciplineModalPage } from "@app/disciplines/choose-discipline-modal/choose-discipline-modal.page";
import { BasePageController } from "@app/pages/BasePage";
import { HelpBotPage } from "@app/shared/help-bot/help-bot.page";
import { ChooseTopicByDisciplinePage } from "@app/topics/shared/choose-topic-by-discipline/choose-topic-by-discipline.page";
import { Guid } from "@app/util/guid";
import { ReviewUtils } from "@app/util/review-utils";
import { UserSubjectUtils } from "@app/util/UserSubjectUtils";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { DefineRevisionData } from "@models/events/DefineRevisionData";
import { Review } from "@models/Review";
import { Statistic } from "@models/Statistic";
import { SubjectItem, UserSubject } from "@models/Subject";
import { Topic } from "@models/topic.model";
import { ReviewCachingService } from "@services/api/caching/review-caching/review-caching.service";
import { StatisticCachingService } from "@services/api/caching/statistic-caching/statistic-caching.service";
import { UserSubjectCachingService } from "@services/api/caching/user-subject-caching/user-subject-caching.service";
import { SubjectService } from "@services/api/subject/subject.service";
import { PremiumService } from "@services/premium/premium.service";
import { ReviewNotificationService } from "@services/review-notification/review-notification.service";
import moment from "moment";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "app-create-review",
  templateUrl: "./create-review.page.html",
  styleUrls: ["./create-review.page.scss"],
})
export class CreateReviewPage extends BasePageController {
  programType = "default";
  lastSequence = 1;
  @Input()
  revisionData: DefineRevisionData = {
    date: null,
    topic: null,
  };
  selectedUserSubject: UserSubject;
  allReviews: Review[] = [];
  subjectReviews: Review[] = [];
  reviews: Review[] = [];
  today: moment.MomentInput = moment().startOf("day");
  subjects: UserSubject[] = [];
  @ViewChild(CustomDateTimeDirective)
  dateComponent: CustomDateTimeDirective;
  revisionPattern = {
    1: true,
    2: false,
    3: false,
    5: false,
    7: true,
    14: true,
    15: false,
    21: false,
    30: true,
    60: false,
    90: false,
    120: false,
  };

  defaultChecked : boolean = true;
  customChecked : boolean = false;

  constructor(
    private userSubjectCachingService: UserSubjectCachingService,
    private reviewCachingService: ReviewCachingService,
    private reviewNotificationsService: ReviewNotificationService,
    private subjectService: SubjectService,
    private statisticCachingService: StatisticCachingService,
    private premiumService: PremiumService,
  ) {
    super();
  }

  get customIsCheck() {
    return this.programType === 'custom';
  }

  get defaultIsCheck() {
    return this.programType === 'default';
  }

  async ionViewDidEnter() {
    this.subjects = await this.userSubjectCachingService.read();

    if (this.revisionData.subjectItem) {
      await this.defineSelectedSubject(this.revisionData.subjectItem);
    }

    this.allReviews = await this.reviewCachingService.read(true);

    if(this.revisionData.lastSequence > 0 && !this.userStoreService.userIsFreemium()) {
      this.programType = 'custom';
      this.revisionPattern = {
        1: false,
        2: false,
        3: false,
        5: false,
        7: false,
        14: false,
        15: false,
        21: false,
        30: true,
        60: true,
        90: true,
        120: true
      };
    } else {
      const data : any = await this.reviewCachingService.getReviewPattern();
      if(data && data.programType === 'custom' && !this.userStoreService.userIsFreemium())  {
        this.revisionPattern = data.revisionPattern;
        this.programType = data.programType;
      }
    }
    
    this.reviews = this.buildReviewsFromPattern();
  }

  private defineSelectedSubject(subjectItem: SubjectItem) {
    this.selectedUserSubject = this.subjects.find((userSubject) => {
      if (userSubject.objectId && subjectItem.objectId)
        return subjectItem.objectId === userSubject.objectId;
      return userSubject.localObjectId === subjectItem.localObjectId;
    });
  }

  private getPattern(): number[] {
    return Object.keys(this.revisionPattern)
      .filter((key) => this.revisionPattern[key])
      .map((n) => parseInt(n));
  }

  isReviewExpired(review: Review) {
    return moment(review.reviewDate).startOf("day").isBefore(this.today);
  }

  async defineProgramType(programType: string) {

    if(programType === 'custom') { 
      const canOpen = await this.premiumService.waitResolvePaywall();
      if(!canOpen) {
        this.programType = 'default';
        return;
      }
    }

    this.programType = programType; 
    if (programType === "default") {
      this.revisionPattern = {
        1: true,
        2: false,
        3: false,
        5: false,
        7: true,
        14: true,
        15:false,
        21: false,
        30: true,
        60: false,
        90: false,
        120: false,
      };

      this.reviews = this.buildReviewsFromPattern();
    }
  }

  toggleCheckReview() {
    this.reviews = this.buildReviewsFromPattern();
  }

  async openChooseTopicModal() {
    if (this.hasStatisticTopic) return;
    if (!this.selectedUserSubject) {
      this.showToastMessage(
        "Selecione uma disciplina para carregar os assuntos"
      );
      return;
    }

    const modalTopicSelection = await this.modalCtrl.create({
      component: ChooseTopicByDisciplinePage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        pageParams: {
          title: "Escolha o assunto",
          canCreateDiscipline: false,
        },
        selectedTopic: this.revisionData.topic,
        discipline: this.selectedUserSubject,
      },
    });
    await modalTopicSelection.present();

    const { data } = await modalTopicSelection.onDidDismiss();

    if (data) {
      const { selectedTopic } = data as { selectedTopic: Topic };
      
      if (selectedTopic) { 
        this.revisionData.topic = selectedTopic; 
      }
    }
  }

  async openChooseDisciplineDialog() {
    console.log('openChooseDisciplineDialog')
    if (this.revisionData.statistic && this.selectedUserSubject) return;

    const loading = await this.showLoading();
    // Load subjects list before opening subject's list page
    if (
      !this.subjectService.subjectItems ||
      this.subjectService.subjectItems.length === 0 ||
      !this.subjectService.userSubjectItems ||
      this.subjectService.userSubjectItems.length === 0
    ) {
      await this.subjectService.loadSubjectItems();
    }
    const modal = await this.modalCtrl.create({
      component: ChooseDisciplineModalPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        pageParams: {
          title: "Escolha a disciplina",
          canCreateDiscipline: true,
        },
      },
    });
    await modal.present();
    await loading.dismiss();
    modal.onDidDismiss().then(async ({ data }) => {
      if (!data || !data.selectedSubjectItem) return;

      let selectedSubjectItem: SubjectItem = data.selectedSubjectItem;
      const isUserSubject = selectedSubjectItem.userSubject;
      if (!isUserSubject) {
        const newDiscipline = await this.createUserSubjectFromGeneral(
          selectedSubjectItem
        );
        selectedSubjectItem = UserSubjectUtils.toSubjectItem(newDiscipline);
      }

      this.subjects = await this.userSubjectCachingService.read();
      this.defineSelectedSubject(selectedSubjectItem);
    });
  }

  private async createUserSubjectFromGeneral(
    subject: SubjectItem
  ): Promise<UserSubject> {
    const newDiscipline: UserSubject = {
      cor: subject.color,
      abreviatura: subject.shortName,
      descricao: subject.name,
      tipo: subject.type,
      user: this.userStoreService.user,
      disciplinaGeral: subject.userSubject
        ? undefined
        : { objectId: subject.objectId },
      localObjectId: subject.localObjectId,
    };

    const existentSubject: SubjectItem =
      this.subjectService.findUserSubjectByDescription(subject);
    if (existentSubject) {
      newDiscipline.objectId = existentSubject.objectId;
      newDiscipline.cor = existentSubject.color;
      newDiscipline.excluida = false;
      await this.userSubjectCachingService.update(newDiscipline);
    } else {
      await this.userSubjectCachingService.create(newDiscipline);
    }

    this.subjectService.loadSubjectItems();
    return newDiscipline;
  }

  buildReviewsFromPattern(): Review[] {
    const reviews: Review[] = [];
    const baseDate = moment(this.revisionData.date);
    const lastSequence = this.revisionData.lastSequence
      ? this.revisionData.lastSequence
      : 0;

    const pattern = this.getPattern();
    this.subjectReviews = [];
    let n = lastSequence + 1;
    const totalReviews = lastSequence + pattern.length;

    pattern.forEach((days) => {
      const reviewDate = baseDate
        .clone()
        .add(days, "days")
        .startOf("day")
        .toDate();
      reviews.push({
        completedAt: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        reviewDate: reviewDate,
        reviewPattern: pattern.join("-"),
        reviewPlanType: this.programType,
        sequence: n++,
        status: "active",
        topic: this.revisionData.topic,
        totalReviews: totalReviews,
        user: this.userStoreService.user,
        userSubject: this.selectedUserSubject,
        localObjectId: Guid.generateLocalObjectId(),
        hasPendingWrite: true,
      });
    });
    return reviews;
  }

  openDateDialog() {
    if (this.revisionData.statistic) return;
    this.dateComponent.date = this.revisionData.date;
    this.dateComponent.onClick(null);
  }

  onTimeSelect(event) {
    if (event) {
      this.revisionData.date = event.date;
      this.reviews = this.buildReviewsFromPattern();
    }
  }

  private validateReviewForm(): boolean {
    let isValid = true;

    if (!this.selectedUserSubject) {
      this.showToastMessage("Selecione uma disciplina para revisar.");
      isValid = false;
      return;
    }

    if (this.reviews.length === 0) {
      this.showToastMessage("Programe ao menos uma revisão.");
      isValid = false;
      return;
    }

    if (
      !this.revisionData.topic ||
      this.revisionData.topic.description.trim().length === 0
    ) {
      this.showToastMessage("Informe o assunto da revisão.");
      isValid = false;
      return;
    }

    return isValid;
  }

  private async resolveStatisticWithoutTopic() {
    if (this.revisionData.statistic && !this.revisionData.statistic.assunto) {
      const statisticToUpdate = { ...this.revisionData.statistic } as Statistic;
      statisticToUpdate.assunto = this.revisionData.topic.description;
      statisticToUpdate.topic = this.revisionData.topic;
      await this.statisticCachingService.update(statisticToUpdate);
    }
  }

  async onClickDefineRevision() {
    if (!this.validateReviewForm()) return;

    const loading = await this.showLoading("Carregando...", false);
    this.amplitudeService.logEvent(AmplitudeEventEnum.BT_REVIEW_ADD_SAVE, {
      subject: this.selectedUserSubject.abreviatura,
      topic: this.revisionData.topic.description,
      review_method: this.programType,
    });

    await this.resolveStatisticWithoutTopic();
  
    let hasOutdatedReviewOnCreation = false;
    const GUID = Guid.newGuid();
  
    this.reviews.forEach((review) => {
      if (!hasOutdatedReviewOnCreation) {
        hasOutdatedReviewOnCreation = this.isReviewExpired(review);
      }
      review.userSubject = this.selectedUserSubject;
      review.topic = this.revisionData.topic;
      review.groupUID = GUID;
    });
  
    this.allReviews = this.allReviews.concat(this.reviews);
    
    this.subjectReviews.forEach((review) => {
      review.totalReviews = this.subjectReviews.length + this.reviews.length;
    });

    await this.reviewCachingService.set(this.allReviews);
    await this.reviewNotificationsService.refreshLocalNotifcations();
   
    if (hasOutdatedReviewOnCreation) {
      this.showRetroativeReviewDialog().then(async () => {
        this.dismiss({
          createdReview: true,
          allReviews: this.allReviews,
        });
        await loading.dismiss();
      });
      return;
    }
  
    if(!this.revisionData.lastSequence){
      await this.reviewCachingService.saveReviewPattern({ 
        programType: this.programType,
        revisionPattern: this.revisionPattern
      });
    }

    await loading.dismiss();

    this.dismiss({
      createdReview: true,
      allReviews: this.allReviews,
    });
  }

  private showRetroativeReviewDialog() {
    return this.Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#3085d6",
      showCancelButton: false,
      title: "Revisões",
      html: '<p class="sweet-alert-text">Há revisões com data retroativa. Você poderá visualizá-las na aba "Vencidas" da tela de "Revisões".</p>',
    });
  }

  async openHelpBot() {
    this.amplitudeService.logEventBtnInfo("create-review");
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        avatar: "assets/imgs/metas/help-bot.png",
        message: `<O agendamento de revisão> leva em conta o <tempo> que você deseja aguardar <a partir da data do estudo> para revisar o assunto.
        Colocamos <como padrão a revisão 1, 7, 14 e 30 dias>, porém você poderá personalizar seu plano de revisão clicando na opção <"Personalizado">.`,
        title: "Revisões",
        buttons: [
          {
            name: "ENTENDI",
            className: "entendi",
            shouldClose: true,
            handler: () => {},
          },
        ],
      },
    });

    await modal.present();
  }
  
  get formattedDate() {
    return this.revisionData && this.revisionData.date
      ? moment(this.revisionData.date).format("DD/MM/YYYY")
      : moment().format("DD/MM/YYYY");
  }

  get hasStatisticTopic() {
    return (
      !!this.revisionData &&
      !!this.revisionData.statistic &&
      !!this.revisionData.statistic.assunto &&
      this.revisionData.statistic.assunto.trim().length > 0
    );
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
}
