import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import ColorUtils from "@app/util/ColorUtils";
import * as _ from "underscore";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import { IPeriod } from "@models/IPeriod";
import { Statistic, StatisticPageType } from "@models/Statistic";
import { AmplitudeService } from "@app/@services/amplitude/amplitude.service";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { StatisticService } from "@app/@services/statistic/statistic.service";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
More(Highcharts);
import Drilldown from "highcharts/modules/drilldown";
Drilldown(Highcharts);
import Exporting from "highcharts/modules/exporting";
import { getChart1, getChart2, getChart3 } from "./chart.config";
import ChartUtils from "@app/util/ChartUtils";
Exporting(Highcharts);
const SlideName = {
  0: "Media de acertos por disciplina/assunto",
  1: "Exercicios por disciplina/assunto",
  2: "Evolucao de acertos",
};

@Component({
  selector: "app-exercise-charts",
  templateUrl: "./exercise-charts.component.html",
  styleUrls: ["./exercise-charts.component.scss"],
})
export class ExerciseChartsComponent implements AfterViewInit {
  @Input() statistics: Array<Statistic>;
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;

  readonly CHART_HEIGHT = 400;


  private statisticsGroupedByDiscipline: Array<Statistic> = [];
  private statisticsWithExercises: Array<Statistic> = [];
  totalExercises = 0;
  totalCorrects = 0;
  exercisesRatio = "0";
  drillDownOpened = false;
  chart: Highcharts.Chart;

  constructor(
    private cdr: ChangeDetectorRef,
    private topicsService: TopicsService,
    private statisticService: StatisticService,
    private amplitudeService: AmplitudeService
  ) {}

  ngAfterViewInit() {
    this.plotAll();
    this.cdr.detectChanges();
  }
  
  get hasStatistics(): boolean {
    this.statisticsWithExercises = this.statistics.filter(
      (item) => item.num_acertos && item.total_exerc
    );
    return this.statisticsWithExercises.length > 0;
  }

  private filterStatisticByTopic(
    topicLocalIds: string[],
    statistics: Statistic[]
  ): { filtered: Statistic[]; statisticsWithNoTopics: Statistic[] } {
    const statisticsWithNoTopics: Statistic[] = [];
    const filtered = statistics.filter((statistic) => {
      if (!statistic.topic || !statistic.topic.localObjectId)
        statisticsWithNoTopics.push(statistic);
      return (
        statistic.topic &&
        statistic.topic.localObjectId &&
        topicLocalIds.includes(statistic.topic.localObjectId)
      );
    });

    return { filtered, statisticsWithNoTopics };
  }

  plotAll() {
    if (this.hasStatistics) {
      this.statisticsGroupedByDiscipline = _.groupBy(this.statisticsWithExercises, "curso");
      this.totalCorrects = this.statisticsWithExercises
        .map(this.mapCorrects)
        .reduce(this.reduceExercises);
      this.totalExercises = this.statisticsWithExercises
        .map(this.mapTotal)
        .reduce(this.reduceExercises);
      this.exercisesRatio = (
        (this.totalCorrects / this.totalExercises) *
        100
      ).toFixed(0);
      this.plotChart1();
      this.plotChart2();
      this.plotChart3();
    } else {
      this.totalCorrects = 0;
      this.totalExercises = 0;
      this.exercisesRatio = "0";
    }
  }

  private plotChart1() {
    const disciplinesData: Array<any> = [];

    _.each(
      this.statisticsGroupedByDiscipline,
      (group: Array<Statistic>, discipline: string) => {
        const totalExercisesByGroup = group
          .map(this.mapTotal)
          .reduce(this.reduceExercises);
        const totalCorrectsByGroup = group
          .map(this.mapCorrects)
          .reduce(this.reduceExercises);
        const percentage = (totalCorrectsByGroup / totalExercisesByGroup) * 100;
        disciplinesData.push({
          name: discipline,
          y: percentage,
          totalExercises: totalExercisesByGroup,
          totalCorrects: totalCorrectsByGroup,
          color: ColorUtils.getColorFromStatistic(group[0]),
          extraData:
            group.length === 0
              ? null
              : { discipline: group[0].disciplina_usuario, group: group },
        });
      }
    );

    const chart = getChart1(
      disciplinesData.map((item) => {
        item.drilldown = true;
        return item;
      })
    );

    let chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 35, this.CHART_HEIGHT) ;

    const exerciseChart1 = new Highcharts.Chart({
      lang: {
        drillUpText: "◁ Voltar",
      },
      chart: {
        type: "column",
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            
            this.logEvent(SlideName[0], "drillup");
            this.statisticService.reset();
            exerciseChart1.setSize(null, chartHeight);
            exerciseChart1.setSubtitle({
              text: `
                <div class="img-detail-click">
                  <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
                  <span>Clique na disciplina para detalhar Assuntos</span>
                </div>
              `,
              useHTML: true,
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[0], "drilldown");
            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: "Média por assunto",
                colorByPoint: true,
                data: [],
              };
              exerciseChart1.showLoading("Aguarde...");
              const topics = await this.topicsService.findTopicsByDiscipline(
                extraData.discipline
              );
              const grpuppedStatistics = extraData.group as Statistic[];
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const { filtered, statisticsWithNoTopics } =
                this.filterStatisticByTopic(topicLocalIds, grpuppedStatistics);
              series.data = topics
                .map((topic) => {
                  const topicStatistics = filtered.filter(
                    (statistic) =>
                      statistic.topic.localObjectId === topic.localObjectId
                  );

                  if (topicStatistics.length === 0) return;

                  const totalExercisesByGroup = topicStatistics
                    .map(this.mapTotal)
                    .reduce(this.reduceExercises, 0);
                  const totalCorrectsByGroup = topicStatistics
                    .map(this.mapCorrects)
                    .reduce(this.reduceExercises, 0);
                  const percentage =
                    (totalCorrectsByGroup / totalExercisesByGroup) * 100;

                  return {
                    name: topic.description,
                    y: percentage,
                    totalExercises: totalExercisesByGroup,
                    totalCorrects: totalCorrectsByGroup,
                    color: topic.color.codigo,
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0) {
                const totalExercisesByGroup = statisticsWithNoTopics
                  .map(this.mapTotal)
                  .reduce(this.reduceExercises, 0);
                const totalCorrectsByGroup = statisticsWithNoTopics
                  .map(this.mapCorrects)
                  .reduce(this.reduceExercises, 0);
                const percentage =
                  (totalCorrectsByGroup / totalExercisesByGroup) * 100;
                series.data.push({
                  color: "#dddddd",
                  name: "Não definido *",
                  y: percentage,
                  totalExercises: totalExercisesByGroup,
                  totalCorrects: totalCorrectsByGroup,
                });
              }
             
              let heightChart = ChartUtils.calculateHeight(series.data.length, 35, this.CHART_HEIGHT);
              exerciseChart1.setSize(null, heightChart);

              exerciseChart1.setSubtitle({
                text: extraData.discipline.descricao,
              });
              exerciseChart1.hideLoading();
              exerciseChart1.addSeriesAsDrilldown(e.point, series);
              this.chart = exerciseChart1;
              this.statisticService.openDrilldown(this);
            }
          },
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      tooltip: chart.tooltip,
      title: chart.title,
      
      subtitle: {
        text: `
          <div class="img-detail-click">
            <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
            <span>Clique na disciplina para detalhar Assuntos</span>
          </div>
        `,
        useHTML: true,
      },
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false,
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: "normal",
        },
        activeDataLabelStyle: {
          fontWeight: "normal",
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15,
          },
          theme: {
            fill: "#007aff",
            "stroke-width": 1,
            stroke: "silver",
            style: {
              color: "white",
              fontWeight: "bold",
            },
          },
        },
        series: [],
      },
    });

    exerciseChart1.reflow();
  }

  private plotChart2() {
    const disciplinesData: Array<any> = [];

    _.each(
      this.statisticsGroupedByDiscipline,
      (group: Array<Statistic>, discipline: string) => {
        const totalExercises = group
          .map(this.mapTotal)
          .reduce(this.reduceExercises);
        disciplinesData.push({
          name: discipline,
          y: totalExercises,
          color: ColorUtils.getColorFromStatistic(group[0]),
          extraData:
            group.length === 0
              ? null
              : { discipline: group[0].disciplina_usuario, group: group },
        });
      }
    );

    const chart = getChart2(
      disciplinesData.map((item) => {
        item.drilldown = true;
        return item;
      })
    );    
    
    const chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 35, this.CHART_HEIGHT);

    const exerciseChart2 = new Highcharts.Chart({
      lang: {
        drillUpText: "◁ Voltar",
      },
      chart: {
        type: "column",
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            this.logEvent(SlideName[1], "drillup");

            this.statisticService.reset();
            exerciseChart2.setSize(null, chartHeight)
            exerciseChart2.setSubtitle({
              text: `
                <div class="img-detail-click">
                  <img style="height:14px; width:14px;" src="assets/imgs/icon-detail-click.png" />
                  <span>Clique na disciplina para detalhar Assuntos</span>
                </div>
              `,
              useHTML: true,
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[1], "drilldown");
            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: "Exerc. por disciplina",
                colorByPoint: true,
                data: [],
              };
              exerciseChart2.showLoading("Aguarde...");
              const topics = await this.topicsService.findTopicsByDiscipline(
                extraData.discipline
              );
              const grpuppedStatistics = extraData.group as Statistic[];
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const { filtered, statisticsWithNoTopics } =
                this.filterStatisticByTopic(topicLocalIds, grpuppedStatistics);
              series.data = topics
                .map((topic) => {
                  const topicStatistics = filtered.filter(
                    (statistic) =>
                      statistic.topic.localObjectId === topic.localObjectId
                  );

                  if (topicStatistics.length === 0) return;

                  const totalExercisesByGroup = topicStatistics
                    .map(this.mapTotal)
                    .reduce(this.reduceExercises, 0);

                  return {
                    name: topic.description,
                    y: totalExercisesByGroup,
                    color: topic.color.codigo,
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0) {
                const totalExercisesByGroup = statisticsWithNoTopics
                  .map(this.mapTotal)
                  .reduce(this.reduceExercises, 0);
                series.data.push({
                  color: "#dddddd",
                  name: "Não definido *",
                  y: totalExercisesByGroup,
                });
              }
              const chartHeightDrillDown = ChartUtils.calculateHeight(series.data.length, 35, 400);
              exerciseChart2.setSize(null, chartHeightDrillDown)

              exerciseChart2.setSubtitle({
                text: extraData.discipline.descricao,
              });
              exerciseChart2.hideLoading();
              exerciseChart2.addSeriesAsDrilldown(e.point, series);
              this.chart = exerciseChart2;
              this.statisticService.openDrilldown(this);
            }
          },
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      tooltip: chart.tooltip,
      title: chart.title,
      subtitle: {
        text: `
          <div class="img-detail-click">
            <img style="width:14px; height:14px" src="assets/imgs/icon-detail-click.png" />
            <span>Clique na disciplina para detalhar Assuntos</span>
          </div>
        `,
        useHTML: true,
      },
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false,
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: "normal",
        },
        activeDataLabelStyle: {
          fontWeight: "normal",
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15,
          },
          theme: {
            fill: "#007aff",
            "stroke-width": 1,
            stroke: "silver",
            style: {
              color: "white",
              fontWeight: "bold",
            },
          },
        },
        series: [],
      },
    });
    exerciseChart2.reflow();
  }

  private plotChart3() {
    const disciplinesData: Array<any> = [];
    const groupByDate = _.groupBy(this.statisticsWithExercises, "data");

    _.each(groupByDate, (group: Array<Statistic>, date: string) => {
      const totalExercisesByGroup = group
        .map(this.mapTotal)
        .reduce(this.reduceExercises);
      const totalCorrectsByGroup = group
        .map(this.mapCorrects)
        .reduce(this.reduceExercises);
      const percentage = (totalCorrectsByGroup / totalExercisesByGroup) * 100;
      disciplinesData.push({
        name: date,
        date: date,
        y: percentage,
      });
    });

    const chart = getChart3(disciplinesData);
   const chart3 = new Highcharts.Chart(chart as any);
   chart3.reflow();
  }

  private mapCorrects(item: Statistic): number {
    return item.num_acertos ? Number(item.num_acertos) : 0;
  }

  private mapTotal(item: Statistic): number {
    return item.total_exerc ? Number(item.total_exerc) : 0;
  }

  private reduceExercises(acc: number, curr: number) {
    return curr + acc;
  }
  
  async slideChanged(event) {
    const { srcElement } = event;
    const {activeIndex} = srcElement.swiper;
    const realIndex = activeIndex;
    const slideName = SlideName[realIndex];
    this.logEvent(slideName);
  }

  logEvent(slideName: string, action?: string) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.SC_STATISTICS_SLIDE, {
      chart: "exercise-chart",
      period: this.tipo,
      slide_name: slideName,
      action,
    });
  }
}
