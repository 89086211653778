import { Component, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { User } from '@models/User';
import { AmplitudeService } from '@services/amplitude/amplitude.service';
import { ApplePurchaseService } from '@services/api/apple-purchase/apple-purchase.service';
import { EstudoService } from '@services/api/estudo/estudo.service';
import { InAppBrowserService } from '@services/api/in-app-browser/in-app-browser.service';
import { OfflineStorageService } from '@services/api/offline-storage/offline-storage.service';
import { ErrorReportService, IErrorReport } from '@services/error-report/error-report.service';
import { EventService } from '@services/event-service/event.service';
import { FirebaseAnalyticsService } from '@services/firebase-analytics.service';
import { LoadingService } from '@services/loading/loading.service';
import moment from 'moment';
const PLANO_ANUAL = 'Estudaqui_Anual';
const PLANO_TRIMESTRAL = 'Estudaqui_Trimestral';
const PLANO_MENSAL = 'Estudaqui_Mensal';


@Component({
  selector: 'app-plan-ios-list',
  templateUrl: './plan-ios-list.page.html',
  styleUrls: ['./plan-ios-list.page.scss'],
})
export class PlanIosListPage extends BasePageController implements OnInit {
  planos: any = [];
  nomePlano: string;
  user: User;
  isTrial = false;
  expiryDate: string;
  isFetchingData = true;
  products: any = [];

  constructor(  
    private loading: LoadingService,
    private estudo: EstudoService,
    private inAppBrowser: InAppBrowserService,
    private events: EventService,
    private iap: InAppPurchase,
    private applePurchase: ApplePurchaseService,
    private storage: OfflineStorageService,
    private errorReportService: ErrorReportService, 
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {
    super();
    this.user = this.userStoreService.user;
   }

  ngOnInit() {
    this.ionViewDidLoad();
  }

  async ionViewDidLoad() {
    this.storage.get<any>('planos-ios-tutorial').then(c => {
      if (!c) {
        //this.openTutorialTab();
      }
    });

    await this.loadData();
    
    this.isFetchingData = false;
  }

  /**
   * Abrir tutorial
   */
  openTutorialTab() {
    this.networkService.executeIfOnline(async () => {
      const modal = await this.modalCtrl.create({component: 'TutorialPlanIosListPage', animated: false});
      modal.present();
    });
  }

  async loadData() {
    const planoAtual = this.user.planoAtual;
    this.isTrial = planoAtual.id_moip === 'Estudaqui_Trial';
    this.expiryDate = moment(this.user.validade).format('DD/MM/YYYY');
    this.planos = await this.estudo.getPlanos();
    const productsPurchase = await this.iap.getProducts([PLANO_ANUAL, PLANO_MENSAL, PLANO_TRIMESTRAL]);

    this.products = productsPurchase;

    for (const plano of this.planos) {
      for (const product of this.products) {
        if (plano.id_moip === product.productId) {
          plano.price = product.price;
          plano.description = product.description;
          plano.productId = product.productId;
        }
      }
    }

    if (planoAtual.id_moip === 'Estudaqui_Anual') {
      this.nomePlano = 'Anual';
    } else if (planoAtual.id_moip === 'Estudaqui_Trimestral') {
      this.nomePlano = 'Trimestral';
    } else {
      this.nomePlano = 'Mensal';
    }

    for (const plano of this.planos) {
      let valorTotal;
      plano.valorMes = plano.valorMes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

      // Todos planos são habilitados na tela de planos do iOS (Apple Requirement)
      plano.disabled = false;

      if (plano.id_moip === 'Estudaqui_Anual') {
        plano.urlImagem = 'assets/imgs/planos/anual.png';
        valorTotal = plano.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        plano.valorTotal = `cobrado anualmente - ${valorTotal}`;
        plano.desconto = `${plano.descontoPorcentagem}% de desconto`;
      } else if (plano.id_moip === 'Estudaqui_Trimestral') {
        plano.urlImagem = 'assets/imgs/planos/trimestral.png';
        valorTotal = plano.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        plano.valorTotal = `cobrado trimestralmente - ${valorTotal}`;
        plano.desconto = `${plano.descontoPorcentagem}% de desconto`;
      } else {
        plano.urlImagem = 'assets/imgs/planos/mensal.png';
        valorTotal = plano.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        plano.valorTotal = `cobrado mensalmente - ${valorTotal}`;
      }
    }
  }

  async buy(plano) {
    await this.loading.show();
    
    try {

      const data = await this.iap.subscribe(plano.productId);
      const { receipt, transactionId } = data

      this.amplitudeService.logEvent('APPLE_BUY', {receipt, transactionId});

      await this.persistPurchase(plano, receipt, transactionId);

    } catch(error) {
      const errorReport: IErrorReport = {
        sentry: {
          err: error,
          tags: {'buyIOS': 'apple'},
          data: plano
        },
        amplitude: {
          event: 'APPLE_BUY_SUBSCRIPTION_ERROR',
          data: {
            error, plano
          }
        }
      }
      this.errorReportService.sendReport(errorReport);
    }
    await this.loading.dismiss();
  }

  async persistPurchase(plano, receipt, transactionId) {
    const expiryDate = moment();

    if (plano.productId === PLANO_ANUAL) {
      expiryDate.add(365, 'days');
    } else if (plano.productId === PLANO_TRIMESTRAL) {
      expiryDate.add(90, 'days');
    } else if (plano.productId === PLANO_MENSAL) {
      expiryDate.add(30, 'days');
    }

    const subscription = {
      customer_id: this.user.objectId,
      plan_id_moip: plano.id_moip,
      plan_id: plano.objectId,
      expiry_date: expiryDate.format(),
      coupon_code: null,
      receipt,
      transactionId,
      amount: parseInt(plano.price.replace(/\D/g, '')) / 100,
    };

    await this.applePurchase.purchase({
      assinatura: subscription,
    });

    this.amplitudeService.logEvent('APPLE_BUY_PERSIST', subscription);
    this.firebaseAnalytics.logEvent('purchase', { planId: subscription.plan_id, amount: subscription.amount })
    this.events.publish('subscription:inAppPurchase');
    this.dismiss();
  }

  navigateToTermsAndPolicy() {
    this.inAppBrowser.openLinkOnBrowser('https://www.estudaqui.com/termos-de-uso-e-politica');
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
}
