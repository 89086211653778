import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input
} from "@angular/core";
import * as moment from "moment";
import * as _ from "underscore";
import ColorUtils from "@app/util/ColorUtils";
import TimeUtils from "@app/util/TimeUtil";
import { AmplitudeEventEnum } from "@models/AmplitudeEventEnum";
import {
  ChartSerie,
  ChartSeries,
  DayDictionary,
  WeekDictionary,
  MonthDictionary,
} from "@models/ChartModel";
import { IPeriod } from "@models/IPeriod";
import { Meta } from "@models/Meta";
import { Statistic, StatisticPageType } from "@models/Statistic";

import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
More(Highcharts);
import Drilldown from "highcharts/modules/drilldown";
Drilldown(Highcharts);
import Exporting from "highcharts/modules/exporting";
import { getChart1, getChart2, getChart3, getChart4 } from "./chart.config";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { StatisticService } from "@app/@services/statistic/statistic.service";
import { AmplitudeService } from "@app/@services/amplitude/amplitude.service";
import ChartUtils from "@app/util/ChartUtils";
import { MetasService } from "@services/api/metas/metas.service";
import { ErrorReportService } from "@services/error-report/error-report.service";
import { Severity } from "@sentry/types";
Exporting(Highcharts);

const SlideName = {
  0: "Tempo de estudo",
  1: "Tempo por disciplina/assunto",
  2: "Distribuicao por disciplina",
  3: "Tipo de estudo",
};

@Component({
  selector: "app-time-charts",
  templateUrl: "./time-charts.component.html",
  styleUrls: ["./time-charts.component.scss"],
})
export class TimeChartsComponent implements AfterViewInit {
  @Input() statistics: Array<Statistic>;
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;
  
  readonly CHART_HEIGHT = 400;
  
  metas: Meta;
  metasArray: Array<number>;
  tempoMetasHHMM = "00:00";
  tempoTotal = 0;
  tempoTotalHHMM = "00:00";
  chart: Highcharts.Chart;
  private statisticsGroupedByDiscipline: Array<Statistic> = [];
  private chartDataGroupedByDiscipline: Array<ChartSerie> = [];
  renderedCharts: Highcharts.Chart[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private topicsService: TopicsService,
    private statisticService: StatisticService,
    private metasService: MetasService,
    private amplitudeService: AmplitudeService,
    private errorReportService: ErrorReportService
  ) {
    this.metas = this.metasService.readMetas()
  }

  ngAfterViewInit() {
    this.plotAll();
    this.cdr.detectChanges();
  }

  get hasStatistics(): boolean {
    return this.statistics && this.statistics.length > 0;
  }

  plotAll() {
    if (this.metas) {
      this.metasArray = [
        this.metas.monday,
        this.metas.tuesday,
        this.metas.wednesday,
        this.metas.thursday,
        this.metas.friday,
        this.metas.saturday,
        this.metas.sunday,
      ];
    } else {
      this.metasArray = [0, 0, 0, 0, 0, 0, 0];
    }
   
    const tempoMetas = this.metasArray.reduce((acc, curr) => acc + curr);

    try {
      this.tempoMetasHHMM = TimeUtils.formatHHMM(tempoMetas);
    } catch (e) {
      this.errorReportService.sendReport({
        sentry: {
          err: e,
          tags: {
            fn: 'TimeChartsComponent:plotAll:tempoMetas',
            data: JSON.stringify({ metasArray: this.metasArray, tempoMetas: tempoMetas || 'nan' })
          },
          level: Severity.Critical
        }
      });

      this.tempoMetasHHMM = TimeUtils.formatHHMM(0);
    }

    if (this.hasStatistics) {
      this.prepareChartData();
      this.renderedCharts = [
        this.plotChart1(),
        this.plotChart2(),
        this.plotChart3(),
        this.plotChart4()
      ];
    } else {
      this.tempoTotal = 0;
      this.tempoTotalHHMM = "00:00";
      this.statisticsGroupedByDiscipline = [];
      this.chartDataGroupedByDiscipline = [];
    }
  }

  private prepareChartData() {
    this.tempoTotal = this.statistics
      .map((statistic) => statistic.tempo)
      .reduce((acc, curr) => acc + curr);
    const totalInHours = this.tempoTotal / (1000 * 60 * 60);
    const hours = Math.floor(totalInHours);
    const minutes = Math.floor((totalInHours - hours) * 60);

    this.tempoTotalHHMM = `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes}`;
    this.statisticsGroupedByDiscipline = _.groupBy(this.statistics, "curso");
    this.chartDataGroupedByDiscipline = [];

    _.each(
      this.statisticsGroupedByDiscipline,
      (group: Array<Statistic>, discipline: string) => {
        const disciplineTime = group
          .map((item) => item.tempo)
          .reduce((acc, curr) => acc + curr);
        this.chartDataGroupedByDiscipline.push({
          color: ColorUtils.getColorFromStatistic(group[0]),
          name: discipline,
          y: disciplineTime,
          extraData:
            group.length === 0
              ? null
              : { discipline: group[0].disciplina_usuario, group: group },
        });
      }
    );
  }

  /**
   * Bar chart for Disciplines data and Spline chart for Goals data. The spline chart must be OVER the bar chart.
   *
   * The data consumed by highcharts is an array in this format:
   *
   * [0, 0, 0, 0, 0, 0, 0]: Where each position is related to time spent studying in one day of the week.
   */
  private plotChart1() {
    const start = this.period.start.clone();
    const end = this.period.end.clone();
    const categories: Array<string | number> = [];
    const series: ChartSeries = {};
    const height: number = 75 * 12;

    if (this.tipo === "semana") {
      const dayDict: DayDictionary = {};

      while (start.isBefore(end) || start.isSame(end)) {
        const day = start.format("YYYY-MM-DD");
        categories.push(day);
        dayDict[day] = 0;
        start.add(1, "day");
      }

      for (const stat of this.statistics) {
        dayDict[stat.data] += stat.tempo;
      }

      series["Study"] = {
        name: "Tempo de estudo",
        data: Object.keys(dayDict).map((key) => dayDict[key]),
      };

      series["Meta"] = {
        type: "spline",
        name: "Meta",
        data: this.metasArray,
      };
    } else if (this.tipo === "mes") {
      const weekDict: WeekDictionary = {};

      const firstDayOfWeek = start.clone().startOf("week").add(1, "day");
      const lastDayOfWeek = start.clone().endOf("week").add(1, "day");

      while (firstDayOfWeek.isBefore(end) || firstDayOfWeek.isSame(end)) {
        const weekRange =
          firstDayOfWeek.format("YYYY-MM-DD") +
          "|" +
          lastDayOfWeek.format("YYYY-MM-DD");
        categories.push(weekRange);

        weekDict[weekRange] = {
          start: firstDayOfWeek.format(),
          end: lastDayOfWeek.format(),
          total: 0,
        };

        firstDayOfWeek.add(1, "week");
        lastDayOfWeek.add(1, "week");
      }

      for (const stat of this.statistics) {
        const date = moment(stat.data, "YYYY-MM-DD");
        for (const range in weekDict) {
          if (
            date.isBetween(
              moment(weekDict[range].start),
              moment(weekDict[range].end),
              "days",
              "[]"
            )
          ) {
            weekDict[range].total += stat.tempo;
          }
        }
      }

      series["Study"] = {
        name: "Study",
        data: Object.keys(weekDict).map((key) => weekDict[key].total),
      };
    } else {
      const monthDict: MonthDictionary = {};

      while (start.isBefore(end) || start.isSame(end)) {
        const month = start.month();
        categories.push(month);
        monthDict[month] = 0;
        start.add(1, "month");
      }

      for (const stat of this.statistics) {
        const date = moment(stat.data, "YYYY-MM-DD");
        const month = date.month();
        monthDict[month] += stat.tempo;
      }

      series["Study"] = {
        name: "Study",
        data: Object.keys(monthDict).map((key) => monthDict[key]),
      };
    }

    const chartConfig = getChart1(categories, series, height, this.tipo);
    const chartHeight = chartConfig.series[0].data.length * 45;
    chartConfig.chart.height = Math.max(this.CHART_HEIGHT, chartHeight);
    const chartone = new Highcharts.Chart(chartConfig as any);
    chartone.reflow();
    return chartone;
  }

  private plotChart2() {
    // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/drilldown/basic/
    // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/drilldown/async/
    // https://stackblitz.com/edit/highcharts-angular-drilldown?embed=1&file=app/app.component.ts

    const chart = getChart2(
      this.chartDataGroupedByDiscipline.map((item) => {
        item.drilldown = true;
        return item;
      })
    );

    const chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 35, this.CHART_HEIGHT);

    const chart2 = new Highcharts.Chart({
      lang: {
        drillUpText: "◁ Voltar",
      },
      chart: {
        type: "column",
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            this.logEvent(SlideName[1], "drillup");
            this.statisticService.reset();
            chart2.setSize(null, chartHeight);
            chart2.setSubtitle({
              text: `
                <div class="img-detail-click">
                  <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
                  <span>Clique na disciplina para detalhar Assuntos</span>
                </div>
              `,
              useHTML: true
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[1], "drilldown");
            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: "-",
                colorByPoint: true,
                data: [],
              };
              chart2.showLoading("Aguarde...");
              const topics = await this.topicsService.findTopicsByDiscipline(
                extraData.discipline
              );
              const grpuppedStatistics = extraData.group as Statistic[];
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const statisticsWithNoTopics: Statistic[] = [];
              const filteredStatistics = grpuppedStatistics.filter(
                (statistic) => {
                  if (!statistic.topic || !statistic.topic.localObjectId)
                    statisticsWithNoTopics.push(statistic);
                  return (
                    statistic.topic &&
                    statistic.topic.localObjectId &&
                    topicLocalIds.includes(statistic.topic.localObjectId)
                  );
                }
              );

              series.data = topics
                .map((topic) => {
                  const sum = filteredStatistics
                    .filter(
                      (statistic) =>
                        statistic.topic.localObjectId === topic.localObjectId
                    )
                    .map((item) => item.tempo)
                    .reduce((acc, cur) => acc + cur, 0);
                  if (sum === 0) return;
                  return {
                    color: topic.color.codigo,
                    name: topic.description,
                    y: sum,
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0)
                series.data.push({
                  color: "#dddddd",
                  name: "Não definido *",
                  y: statisticsWithNoTopics
                    .map((i) => i.tempo)
                    .reduce((acc, cur) => acc + cur, 0),
                });
              
              
              const chartHeightDrillDown = ChartUtils.calculateHeight(series.data.length, 35, this.CHART_HEIGHT);
              chart2.setSize(null, chartHeightDrillDown);
              chart2.setSubtitle({
                text: extraData.discipline.descricao,
              });
              chart2.hideLoading();
              chart2.addSeriesAsDrilldown(e.point, series);
              this.chart = chart2;
              this.statisticService.openDrilldown(this);
            }
          },
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: chart.title,
      subtitle: {
        text: `
          <div class="img-detail-click">
            <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
            <span>Clique na disciplina para detalhar Assuntos</span>
          </div>
        `,
        useHTML: true,
        style: {
          fontSize: '10px'         
        }        
      },
      tooltip: chart.tooltip,
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false,
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: "normal",
        },
        activeDataLabelStyle: {
          // textDecoration: 'none',
          // color: '#666666',
          fontWeight: "normal",
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15,
          },
          theme: {
            fill: "#007aff",
            "stroke-width": 1,
            stroke: "silver",
            style: {
              color: "white",
              fontWeight: "bold",
            },
          },
        },
        series: [],
      },
    });
    chart2.reflow();
    return chart2;
  }

  private plotChart3() {
    const chart = getChart3(this.chartDataGroupedByDiscipline);
    const chart3 = new Highcharts.Chart(chart as any);
    chart3.reflow();
    return chart3;
  }

  private plotChart4() {
    const seriesData: Array<any> = [];
    const groupByTipoEstudo: Array<any> = _.groupBy(
      this.statistics,
      "tipo_estudo"
    );

    _.each(groupByTipoEstudo, (group: Array<Statistic>, tipo: string) => {
      const typeTime = group
        .map((item) => item.tempo)
        .reduce((acc, curr) => acc + curr);
      seriesData.push({
        name: tipo,
        y: typeTime,
      });
    });

    const chart = getChart4(seriesData);
    const chart4 = new Highcharts.Chart(chart as any);
    chart4.reflow();
    return chart4;
  }

  async slideChanged(event) {
    const { activeIndex } = event.srcElement.swiper;
    const slideName = SlideName[activeIndex];
    this.logEvent(slideName);
  }

  logEvent(slideName: string, action?: string) {
    this.amplitudeService.logEvent(AmplitudeEventEnum.SC_STATISTICS_SLIDE, {
      chart: "time-chart",
      period: this.tipo,
      slide_name: slideName,
      action,
    });
  }
}
