import { Injectable } from '@angular/core';
import amplitude, { AmplitudeClient, Callback, Identify } from 'amplitude-js';
import { environment } from '../../../environments/environment';
import { AmplitudeEventEnum } from '../../models/AmplitudeEventEnum';
import { WebUtils } from '@app/util/WebUtils';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  private client: AmplitudeClient;

  constructor() {
    this.client = amplitude.getInstance();
    this.client.init(environment.amplitudeApiKey, undefined, { includeReferrer: true, includeUtm: true });
    this.client.setVersionName(environment.appVersion);
    this.client.setUserProperties({ isWebVersion:  WebUtils.isWeb() });
  }
  setUserId(userId: string) {
    this.client.setUserId(userId);
  }

  setUserProperties(user) {
    const { planId, planName } = user.planoAtual;

    this.client.setUserProperties({
      planId: planId || null,
      planName: planName || null
    });
  }

  setUserObjective(objective: string) {
    this.client.setUserProperties({ objective });
  }

  setUserPlan(planId: string, expired: boolean, planExp: Date, planName: string) {
    const identify: Identify = new this.client.Identify();
    identify.set('planId', planId);
    identify.set('planName', planName);
    this.client.setUserProperties({
      plan_id: planId,
      plan_exp: planExp,
      expired,
      plan_name: planName
    });
  }

  logEvent(event: AmplitudeEventEnum | string, data?: any, cb?: Callback) {
    return this.client.logEvent(event, data, cb);
  }

  logEventBtnInfo(origin: string) {
    this.logEvent(AmplitudeEventEnum.BT_INFO, { origin });
  }

  logPageEvent(view) {
    try {
      const pageName = view.instance.constructor.name;
      const pageConfig = PageNameEventParams[pageName];
      if (pageConfig) {
        const { eventType, context } = pageConfig;
        this.logEvent(eventType, context);
      }
    } catch (e) {
      console.log(e);
    }
  }

  logEventReviewSegment(index: number) {
    const eventType = SegmentsAmplitudeEventType[index];
    this.logEvent(eventType);
  }

  logBtnEventChronometer(type: AmplitudeEventEnum, subjectShortName: string, freeSubject: boolean) {
    const recordType = freeSubject === true ? 'Estudo livre' : 'Estudo planejado';
    this.logEvent(type, {
      subject: subjectShortName,
      record_type: recordType
    });
  }

  logGenericButtonEvent(target: HTMLElement) {
    try {
      const { className, nodeName, innerText, children } = target;

      if (className && ['button', 'icon'].some((e) => className.includes(e))) {
        let ariaLabelAux;

        if (children && children.length > 0) {
          const sample = children[0];
          const ariaLabel = sample['ariaLabel'];
          ariaLabelAux = ariaLabel;
        }
        const origin = document.URL.replace('http://localhost:8100/#', '');
        this.logEvent(AmplitudeEventEnum.BT_GENERIC_EVENT, { innerText, ariaLabelAux, origin });
      }
    } catch (e) {
      console.log({ e });
    }
  }
}

const PageNameEventParams = {
  CardsPage: { eventType: AmplitudeEventEnum.SC_HISTORY },
  CreateCyclePage: { eventType: AmplitudeEventEnum.SC_CYCLE_CREATE },
  CreateAutomaticCyclePage: { eventType: AmplitudeEventEnum.SC_CYCLE_CREATE_AUTOMATIC },
  ReviewListPage: { eventType: AmplitudeEventEnum.SC_REVIEW },
  ChronometerDisciplinesListPage: { eventType: AmplitudeEventEnum.SC_STUDY_RECORD },
  CronometroModalPage: { eventType: AmplitudeEventEnum.SC_STUDY_RECORD_INFO },
  TimeRegisterSummaryPage: { eventType: AmplitudeEventEnum.SC_STUDY_SUMMARY },
  TopicListPage: { eventType: AmplitudeEventEnum.SC_MY_SUBJECTS_TOPICS },
  PlanosPage: { eventType: AmplitudeEventEnum.SC_SUBSCRIPTION_PLANS },
  TutorialAutomaticCyclePage: { eventType: AmplitudeEventEnum.BT_INFO, context: { origin: 'create_automatic_cycle' } },
  TutorialEstatisticaPage: { eventType: AmplitudeEventEnum.BT_INFO, context: { origin: 'statistic' } },
  TutorialHistoricoPage: { eventType: AmplitudeEventEnum.BT_INFO, context: { origin: 'historic' } },
  TutorialCronometroPage: { eventType: AmplitudeEventEnum.BT_INFO, context: { origing: 'study_record' } },
  StatisticHistoryTabPage: { eventType: AmplitudeEventEnum.SC_HISTORY },
  ChronometerPage: { eventType: AmplitudeEventEnum.SC_CHRONOMETER }
};

const SegmentsAmplitudeEventType = {
  0: AmplitudeEventEnum.TAB_REVIEW_DONE,
  1: AmplitudeEventEnum.TAB_REVIEW_DUE,
  2: AmplitudeEventEnum.TAB_REVIEW_TODAY,
  3: AmplitudeEventEnum.TAB_REVIEW_FUTURE
};
