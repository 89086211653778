import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ESTUDAQUI_PLANO_TRIAL } from '@app/util/util';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { AmplitudeService } from '@app/@services/amplitude/amplitude.service';
import { InAppBrowserService } from '@app/@services/api/in-app-browser/in-app-browser.service';
import { MoipService } from '@app/@services/api/moip/moip.service';
import { UserStoreService } from '@app/@services/api/user-store/user-store.service';
import { StorageService } from '@app/@services/storage/storage.service';
import { EventService } from '@services/event-service/event.service';

@Component({
  selector: 'app-welcome-trial',
  templateUrl: './welcome-trial.page.html',
  styleUrls: ['./welcome-trial.page.scss']
})
export class WelcomeTrialPage {
  statusDictionary: any = {
    VENCIDA: 'VENCIDA',
    AGUARDANDO: 'AGUARDANDO_PAGAMENTO',
    RECUSADA: 'PAGAMENTO_RECUSADO'
  };
  status = 'VENCIDA';
  constructor(
    private inAppBrowser: InAppBrowserService,
    private storageService: StorageService,
    public userStore: UserStoreService,
    private amplitudeService: AmplitudeService,
    private loadingController: LoadingController,
    private modalCtrl: ModalController
  ) {}

  get isTrial(): boolean {
    this.userStore.userIsFreemium();
    if (this.userStore.user) {
      return (
        (this.userStore.user &&
          this.userStore.user.assinaturaAtual &&
          this.userStore.user.assinaturaAtual.plano &&
          this.userStore.user.assinaturaAtual.plano.objectId === ESTUDAQUI_PLANO_TRIAL) ||
        this.userStore.user.planoAtual.objectId === ESTUDAQUI_PLANO_TRIAL ||
        !this.userStore.user.assinaturaAtual
      );
    }
    return false;
  }

  ionViewDidLoad() {
    this.logEvent();
  }

  exit() {
    this.storageService.remove('user-estudo');
    // this.navCtrl.setRoot(FirstRunPage);
  }

  navigate() {
    this.inAppBrowser.openLinkOnBrowser('https://www.estudaqui.com/');
  }

  async viewPlanos() {
    const loading = await this.loadingController.create();
    await loading.present();

    // if (this.platform.is('ios')) {
    //   const assinante = await this.moip.consultarAssinante(this.userStore.user.objectId);

    //   /**
    //    * Verificação para confirmar se o usuário adquiriu o plano via Android/Web. Caso sim, mostramos os menus de
    //    * gerenciamento que fazem interface com os servidores da Wirecard.
    //    */
    //   if (assinante) {
    //     this.navCtrl.push('CheckoutSelectPlanPage');
    //   } else {
    //     this.navCtrl.push('PlanosIosPage');
    //   }
    // } else {
    //   this.navCtrl.push('CheckoutSelectPlanPage');
    // }

    await loading.dismiss();
  }

  continueTesting() {
    this.modalCtrl.dismiss();
  }

  logEvent() {
    try {
      const { nome } = this.userStore.user.planoAtual;
      const { validade } = this.userStore.user;
      this.amplitudeService.logEvent(AmplitudeEventEnum.SC_WELCOME_TRIAL, {
        plan_name: nome,
        plan_exp: validade,
        bill_status: 'TRIAL'
      });
    } catch (e) {
      console.error(e);
    }
  }

  get currentUserEmail() {
    return this.userStore && this.userStore.user ? this.userStore.user.username : '';
  }

  get currentUserExpiryDate() {
    return this.userStore && this.userStore.user ? moment(this.userStore.user.validade).format('DD/MM/YYYY') : '';
  }

  get currentUserFullname() {
    return this.userStore && this.userStore.user ? this.userStore.user.fullname : '';
  }
}
