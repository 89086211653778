import { Injectable } from '@angular/core';
import { SentryService } from '@app/core/sentry.service';
import { Guid } from '@app/util/guid';
import { Severity } from '@sentry/angular';
import { AmplitudeService } from '@services/amplitude/amplitude.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { DeviceService } from '@services/device/device.service';
import { Callback } from 'amplitude-js';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportService {

  constructor(
    private device: DeviceService,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private userStoreService: UserStoreService
  ) { }


  sendReport(report: IErrorReport): string {
    const errorReportID = `${Guid.newGuid()}_${Date.now()}`;
    const { sentry, amplitude } = report;

    sentry.tags['error_report_id'] = errorReportID;
    this.sentryService.withScope(sentry.tags, sentry.err, sentry.level, sentry.data);

    if (amplitude) {
      this.amplitudeService.logEvent(amplitude.event, { ...amplitude.data, errorReportID }, amplitude.cb);
    }

    return errorReportID;
  }

  async sendReportAndShowEmail(e: any, report: IErrorReport) {
    const jsonErr = JSON.stringify(e, Object.getOwnPropertyNames(e));
    const errorReportID = this.sendReport(report);
    const deviceOS = `${await this.device.getPlatform()} ${await this.device.getVersion()}`;

    const userEmail: string = this.userStoreService.user?.email ?? "";
    const subject: string = encodeURIComponent(`Report Erro - Versão ${environment.appVersion} ${encodeURIComponent(userEmail)}`);
    const bodyBase = `Ocorreu um erro durante \n\n - id: ${errorReportID} \n\n -device: ${deviceOS} \n\n`;
    const body: string = encodeURIComponent(`${bodyBase}  Erro: ${jsonErr}`);
    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'ENTENDI',
      confirmButtonColor: '#3085d6',
      showCancelButton: false,
      title: 'Alerta',
      html: `<p class="sweet-alert-text">Ocorreu um erro desconhecido, feche o aplicativo e tente novamente.</p>
      <p class="sweet-alert-text">Se o erro persistir nos avise <a href="mailto:suporte@estudaqui.com?subject=${subject}&body=${body}" class="msg-email">clicando aqui</a>.</p>`,
    });
  }
}

export interface IErrorReport {
  sentry: {
    tags: { [prop: string]: string };
    err: any;
    level?: Severity;
    data?: any;
  };
  amplitude?: {
    event: string;
    data?: any;
    cb?: Callback;
  };
}
