import { Component, Input, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { AmplitudeEventEnum } from '@models/AmplitudeEventEnum';
import { InAppBrowserService } from '@services/api/in-app-browser/in-app-browser.service';
import { SideMenuService } from '@services/side-menu/side-menu.service';


@Component({
  selector: 'app-modal-rated',
  templateUrl: './modal-rated.page.html',
  styleUrls: ['./modal-rated.page.scss'],
})
export class ModalRatedPage extends BasePageController implements OnInit {
  isFiveStars: boolean = false;
  @Input() rateValue: number = 0;

  constructor(
    public sideMenuService: SideMenuService,
    private inAppBrowserService: InAppBrowserService) { 
    super()
  }

  ngOnInit() {
    this.isFiveStars = this.rateValue > 4;
  }

  ionViewDidEnter() {
    this.amplitudeService.logEvent(AmplitudeEventEnum.POPUP_RATED, {rateValue: this.rateValue});
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  openRateAPP() {
    try {

      this.amplitudeService.logEvent(AmplitudeEventEnum.BT_RATE_OPEN_STORE, {rateValue: this.rateValue});
      const storeUrl = this.platform.is("ios")
      ? "itms-apps://itunes.apple.com/app/id1445700738"
      : "market://details?id=br.estudaqui.com";

      this.inAppBrowserService.openLinkOnBrowser(storeUrl);
      this.close();
    } catch(e) {
      console.error(e)
      alert(e)
    }
  }

  onImprovementsSurvey() {
    this.sideMenuService.onImprovementsSurvey();
    this.close();
  }
  
}
