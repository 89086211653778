import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import { ChooseColorPage } from "@app/pages/choose-color/choose-color.page";
import { UserSubjectUtils } from "@app/util/UserSubjectUtils";
import { IonReorderGroup, NavParams } from "@ionic/angular";
import { Color } from "@models/Color";
import { UserSubject } from "@models/Subject";
import { Topic } from "@models/topic.model";
import { ColorCachingService } from "@app/@services/api/caching/color-caching/color-caching.service";
import { ReviewCachingService } from "@app/@services/api/caching/review-caching/review-caching.service";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { DisciplineService } from "@app/@services/api/discipline/discipline.service";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { EventService } from "@app/@services/event-service/event.service";
import { StatisticService } from "@app/@services/statistic/statistic.service";
import { CreateTopicPage } from "../create-topic/create-topic.page";
import { BackButtonService } from "@services/back-button/back-button.service";

@Component({
  selector: 'app-discipline-topics',
  templateUrl: './discipline-topics.page.html',
  styleUrls: ['./discipline-topics.page.scss']
})
export class DisciplineTopicsPage extends BasePageController implements OnDestroy {
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  @Input() discipline: UserSubject;
  allTopics: Topic[] = [];
  deletedTopics: Topic[] = [];
  topics: Topic[] = [];
  topicsBeforeEditing: Topic[] = [];
  isEditing = false;

  constructor(
    private topicsService: TopicsService,
    private userSubjectCachingService: UserSubjectCachingService,
    private colorCachingService: ColorCachingService,
    private eventService: EventService,
    private disciplineService: DisciplineService,
    private statisticService: StatisticService,
    private reviewCachingService: ReviewCachingService,
    private backButtonService: BackButtonService
  ) {
    super();
  }

  async ionViewWillEnter() {
    const sub = this.backButtonService.subscribeWithPriority(110, (next) => {
      if (this.isEditing) {
        this.cancelEdition();
        return;
      }
      next();
    });
    this.pushSubscription(sub);
    this.loadData();
  }

  ngOnDestroy() {
    this.handleLeave();
  }

  async loadData() {
    const loading = await this.showLoading();

    try {
      this.allTopics = await this.topicsService.getAll();
      this.extractCurrentTopicsFromAll(this.discipline);
    } catch (e) {
      this.showToast({
        message: 'Ops. Um erro desconhecido ocorreu. Tente novamente'
      });
    }

    loading.dismiss();
  }

  private async confirmDelete(discipline: UserSubject) {
    this.amplitudeService.logEvent('EV_discipline_topics_delete', {
      disciplineId: discipline.objectId,
      disciplineLocalObjectId: discipline.localObjectId,
    });
    const loading = await this.showLoading();
    
    discipline.excluida = true;
    await this.userSubjectCachingService.delete(discipline);
   
    const disciplines = await this.disciplineService.getDisciplinesFromGeneralAndUser(
      discipline.objectId,
      discipline.localObjectId,
      true
    );

    await this.statisticService.updateColorStatisticFromDisciplines(
      discipline.objectId,
      discipline.localObjectId,
      disciplines
    );

    this.eventService.publish('color:change', {
      localObjectId: discipline.localObjectId,
      objectId: discipline.objectId,
      subjects: discipline,
      isUserSubject: true
    });
    await loading.dismiss();
    await this.dismiss();
  }

  openInfoModal() {
    this.topicsService.showTopicsAndDisciplinesInfoDialog();
  }

  async goToTopicDetails(topic: Topic) {
    if (this.isEditing) return;
    const topicsModal = await this.modalCtrl.create({
      component: CreateTopicPage,
      cssClass: 'transparent sd',
      animated: false,
      componentProps: {
        discipline: this.discipline,
        topic: topic,
        topics: this.topics
      }
    });
    topicsModal.present();
    topicsModal.onDidDismiss().then(async (response) => {
      const { data } = response;
      if (data && data.shouldReloadTopics) await this.reloadTopics();
    });
  }

  private extractCurrentTopicsFromAll(discipline: UserSubject) {
    this.allTopics.forEach((topic, index) => {
      if (topic.flgDeleted || !topic.userDiscipline) return;
      const found =
        (discipline.localObjectId && discipline.localObjectId === topic.userDiscipline.localObjectId) ||
        discipline.objectId === topic.userDiscipline.objectId;

      if (found) {
        this.topics.push({ ...topic });
        this.allTopics[index] = null;
      }
    });

    this.allTopics = this.allTopics.filter((topic) => topic);
    this.topics.sort((a, b) => a.order - b.order);
  }

  startEdition() {
    this.topicsBeforeEditing = [...this.topics];
    this.isEditing = true;
    this.reorderGroup.disabled = false;
  }

  doReorder(ev) {
    const { from, to } = ev.detail;
    let draggedItem = this.topics.splice(from, 1)[0];
    this.topics.splice(to, 0, draggedItem);
    ev.detail.complete();
  }

  cancelEdition() {
    this.topics = [...this.topicsBeforeEditing];
    this.topicsBeforeEditing = [];
    this.isEditing = false;
    this.reorderGroup.disabled = true;
  }

  async showDialogConfirmTopicDeletion(index: number) {
    const topic = this.topics[index];

    const alert = await this.alertCtrl.create({
      header: `Excluir assunto`,
      subHeader: `${topic.description} da disciplina ${topic.userDiscipline.descricao}`,
      message: 'Você deseja continuar ?',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      }, 
      {
        text: 'Sim',
        handler: async ()=> {
          await this.deleteTopicByIndex(index);
          await this.saveChanges();
        }
      }]
    });

    await alert.present();
  }

  private async reloadTopics() {
    this.topics = [];
    this.allTopics = await this.topicsService.getAll();
    this.extractCurrentTopicsFromAll(this.discipline);

    this.discipline.numTopics = this.topics.length;
    await this.userSubjectCachingService.update(this.discipline);
  }

  private deleteTopicByIndex(index: number) {
    const topicDeleted = {...this.topics[index], hasPendingWrite: true, flgDeleted: true };
    this.topics.splice(index, 1);
    this.deletedTopics.push(topicDeleted);
  }

  async saveChanges() {
    const loading = await this.showLoading();
        
    this.topics.forEach((topic, i) => {
      topic.order = i + 1;
      topic.hasPendingWrite = true;
    });
    this.discipline.numTopics = this.topics.length;  
    
    await this.topicsService.updateBatch(this.topics);
    await this.topicsService.deleteBatch(this.deletedTopics)

    await this.userSubjectCachingService.update(this.discipline);
    
    this.topicsBeforeEditing = [];
    this.deletedTopics = [];
    this.isEditing = false;
   
    await loading.dismiss();
  }

  async openTopicModal() {
    const topicsModal = await this.modalCtrl.create({
      component: CreateTopicPage,
      cssClass: 'transparent sd',
      componentProps: {
        discipline: this.discipline,
        topics: this.topics
      }
    });
    await topicsModal.present();
    topicsModal.onDidDismiss().then(async (response) => {
      const { data } = response;
      if (data) {
        const { createdTopic, discipline } = data as {
          createdTopic: Topic;
          discipline: UserSubject;
        };
        this.topics.push(createdTopic);
        await this.userSubjectCachingService.update(discipline);
      }
    });
  }

  async openModalDisciplineUpdateDialog(discipline: UserSubject) {
    const colors: Array<Color> = await this.colorCachingService.read();
    const modal = await this.modalCtrl.create({
      component: ChooseColorPage,
      cssClass: 'transparent sd',
      componentProps: {
        colors,
        subject: UserSubjectUtils.toSubjectItem(discipline)
      }
    });
    await modal.present();

    modal.onDidDismiss().then(async (response) => {
      const { data } = response;
      if (data && data.discipline) {
        const loading = await this.showLoading();
        this.discipline = data.discipline;
        const disciplines = await this.disciplineService.getDisciplinesFromGeneralAndUser(
          discipline.objectId,
          discipline.localObjectId,
          true
        );
        await this.statisticService.updateColorStatisticFromDisciplines(
          discipline.objectId,
          discipline.localObjectId,
          disciplines
        );
        this.reviewCachingService.refreshReviews();
        this.userSubjectCachingService.sync();
        await loading.dismiss();
        this.eventService.publish('color:change', {
          localObjectId: discipline.localObjectId,
          objectId: discipline.objectId,
          subjects: disciplines,
          isUserSubject: true
        });
      }
    });
  }

  async doDisciplineDeletion(discipline: UserSubject) {
    const alert = await this.alertCtrl.create({
      header:'Excluir disciplina da sua lista pessoal ?',
      message: `Serão excluídos tanto a disciplina quanto os assuntos vinculados a ela.
      Seu histórico de estudo e estatística serão mantidos.`,
      buttons: [{text: 'Cancelar', role: 'cancel'}, {text: 'Sim', handler: () => this.confirmDelete(discipline)}]
    });

    await alert.present();
  }


  private handleLeave() {
    this.allTopics = null;
    this.topics = null;
    this.topicsBeforeEditing = null;
    this.discipline = null;
    this.deletedTopics = null;
    this.unsubscribeAllSubscriptions();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
